import { typesofUsers } from "../config";


export function checkModuleAccess(ModuleData, moduleName, menuName) {    
    if (!ModuleData ) {
        return false;
    }
    if(!ModuleData[moduleName])
    {
        return true;
    }
    const parentMenu = ModuleData['ParentMenus'].filter(item=>item != null).find(parent => parent?.MenuName === moduleName);
    console.log(parentMenu,"parentMenu2323")


    // If parent menu is not found or has no access, return false
    if (!parentMenu || parentMenu.Access === false) {
        return false;
    }
    if(ModuleData[moduleName].findIndex(item=>item.Access == true)==-1){
        return false
    }
    if(parentMenu.Access==true && menuName == " "){
        return true;
    }
    // Find the menu item within the module
    const menu = ModuleData[moduleName].find(item => item?.MenuName === menuName);

    // Return true if the menu item doesn't exist
    if (!menu) {
        return true;
    }

    // Check user access if the menu item exists
    return menu.Access === true;
}
export function isSuperAdmin(accessStatus,userRole) {
    console.log(userRole,typesofUsers.docuverusSuperAdmin,"userrolre")
   if(userRole === typesofUsers.docuverusSuperAdmin)
    return true ;
   else 
   return accessStatus;
}
// Sample usage:
// const ModuleData = {
//     "Dashboard": [
//         {
//             "MenuName": "Send Invite",
//             "Access": true,
//             "Active": true
//         },
//         {
//             "MenuName": "Generate Report",
//             "Access": false,
//             "Active": true
//         }
//     ],
//     "Inbox": [
//         {
//             "MenuName": "View PII",
//             "Access": true,
//             "Active": true
//         }
//     ]
// };

// console.log(hasModuleAccess(ModuleData, 'Dashboard', 'Send Invite'));  // Output: User has access to Dashboard.Send Invite
// console.log(hasModuleAccess(ModuleData, 'Dashboard', 'Generate Report'));  // Output: User does not have access to Dashboard.Generate Report
// console.log(hasModuleAccess(ModuleData, 'Inbox', 'View PII'));  // Output: User has access to Inbox.View PII
// console.log(hasModuleAccess(ModuleData, 'Settings', 'Permissions'));  // Output: Settings does not exist
 