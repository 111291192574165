import { format } from "date-fns"
import { BooleanField, Datagrid, DateField, FunctionField, List, ListBase, ListGuesser, ListToolbar, NumberField, Pagination, Resource, TextField, useListContext, useNotify, useStore, Confirm, DeleteButton, DeleteWithConfirmButton, Button, useDelete } from "react-admin"
import {
  Grid,
  Typography,
  TextField as MuiTextField,
  Box,
  Link,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  Stack,
  Button as MuiButton
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PDF from "./../../../../assets/images/Frame (5).png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";

import { debounce } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import React from "react";
import FilterChips from "../Ats/SuperAdminInbox/FilterChips";
import NotificationFilter from "./NotificationFilter";
import { JSONTree } from "react-json-tree";

export const SystemNotificationResource = () => {
  return <Resource name="getSystemNotification" list={SystemNotification} />
}
const bgStyle = (condition: string) => {
  let style = {};
  switch (condition) {
    case "CompletedConditionallyApproved":
      style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
      break;
    case "NA":
      style = { backgroundColor: "#E9E9E9", color: "#909090" };
      break;
    case "Completed-Unacceptable":
      style = { backgroundColor: "#F8ECE2", color: "#FF4848" };
      break;
    case "Completed-Approved":
      style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
      break;
    case "Invited":
      style = { backgroundColor: "#E9E9E9", color: "#909090" };
      break;
    case " In Progress":
      style = { backgroundColor: "#DDF8FF", color: "#1CA2C2" };
      break;
    case "Submitted":
    case "Review Pending":
      style = { backgroundColor: "#B0EFFF", color: "#127790" };
      break;
    // case "Screening":
    case "Completed":
    case "Valid":
    case "Active":
    // case "Completed-Approved":
    case "Verified":
      style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
      break;
    case "Cancelled":
    case "Archived":
    // case "Completed-Unacceptable":
    case "Invalid":
    case "Inactive":
    case "Unverifiable":
      style = { backgroundColor: "#FFEEEE", color: "#FF4848" };
      break;
    case "Completed-Conditionally Approved":
    case "CompletedConditionallyApproved":
    case "Further Documentation Required":
    case "Verification Required":
    case "Further Doc Req.":
      style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
      break;
    default:
      style = {};
  }
  return style;
};
const MyListToolBar = () => {
  const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
    useListContext();
  const [searchValue, setSearchValue] = useState('');
  const [applicantType, setApplicantType] = useState("All")
  const navigate = useNavigate();
  // const [q, setQ] = React.useState<null | string>(filterValues?.q);
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  // Debounce the search function
  const debouncedSearch = debounce((value) => {
    // Perform your search logic here
    setFilters({ ...filterValues, q: value }, []);
  }, 500); // 500ms debounce delay

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    setFilters({ ...filterValues, q: value }, []);
  };
  React.useEffect(() => {
    // console.log("This is useEffect", filterValues.q);

    if (filterValues.q === undefined) {
      setSearchValue(''); // Reset q to an empty string if filterValues.q is undefined
    } else {
      setSearchValue(filterValues.q); // Otherwise, set q to the value in filterValues.q
    }

  }, [filterValues.q]);
  return (
    <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
      {/* Left side with title */}
      <Grid item xs={12} md={6}>

        <p style={{ font: "Open Sans", fontWeight: 600, fontSize: '18px' }}>System Notifications</p>



      </Grid>

      {/* Right side with search input, filter button, and add button */}
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <div className="group-btn">
          {/* Search Input */}
          <MuiTextField
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            // defaultValue={filterValues.q}
            value={searchValue}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
          />
          <NotificationFilter />

          <button
            type="button"
            onClick={() =>
              navigate(`/ats/adminnotification/addnotification`, {
                state: { isAdding: true },
              })
            }
            className="blue-btn px-4"
          >
            <svg
              className="mr-r10"
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.33337 4.83333V0.833334H5.66671V4.83333H9.66671V6.16667H5.66671V10.1667H4.33337V6.16667H0.333374V4.83333H4.33337Z"
                fill="white"
              />
            </svg>
            Notification
          </button>


        </div>
      </Grid>
    </Grid>
  );
};
const MyList = ({ children, toolBar, ...props }) => {
  return (
    <ListBase
      {...props}
      perPage={100} // Number of items per page
    >
      {toolBar}
      <FilterChips excludeKeys={['app_invitation_type']} />
      {children}

      <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
      <br />
      <br />
      <br />
      <br />
    </ListBase>
  );
};
const ActiveBtn = () => {
  const myStyles = {
    display: "flex",
    width: "79px",
    padding: "8px 11px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    background: "#E6FDF0",
  };
  const myTextStyles = {
    color: "var(--Success, #46A96F)",
    // textAlign: 'center',
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };
  return (
    <div style={myStyles}>
      <div style={myTextStyles}>Active</div>
    </div>
  );
};
const DeActiveBtn = () => {
  const myStyles = {
    display: "flex",
    width: "79px",
    padding: "8px 11px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    background: "#FFF1F1",
  };
  const myTextStyles = {
    color: "var(--Success, #FF4848)",
    // textAlign: 'center',
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };
  return (
    <div style={myStyles}>
      <div style={myTextStyles}>Inactive</div>
    </div>
  );
};
const SystemNotification = () => {

  return (
    <>
      <MyList
        toolBar={<MyListToolBar />}
        sort={{ field: "id", order: "DESC" }}
        children={<DataGrid />}
      />

    </>
  )
}
const DataGrid = () => {
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  //   const dataProvider = useDataProvider();
  //   const queryClient = useQueryClient();
  //   const notify = useNotify();
  //   const refresh=useRefresh();
  //   const dispatch = useDispatch();
  //   const { mutate: deletedNotification, isLoading: deleteDocumentNoDocIsLoading } = useMutation(tag, (id) => dataProvider.deleteDocumentNoDoc(id), {
  //     onSuccess: (data) => {
  //         setConfirmDelete(false);
  //         queryClient.invalidateQueries([`${invalidateQueries}`])
  //         notify('Document deleted successfully...', { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
  //     },
  //     onError: (data) => {
  //         setConfirmDelete(false);
  //         alert("Fail deleting document")
  //     },
  //     onMutate: () => {
  //         notify('Document is Deleting...', { type: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
  //     }
  // })
  const navigate = useNavigate();
  const notify = useNotify();
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [deleteOne] = useDelete();

  const handleDelete = (id) => {
    deleteOne(
      'getSystemNotification', // Resource name, replace with your resource
      { id }, // Record ID to delete
      {
        onSuccess: () => {
          notify('Notification Deleted Successfully.', { type: 'success', anchorOrigin: { vertical: "top", horizontal: "right" }, });
          setShowConfirm(false); // Close confirmation dialog after success
        },

      }
    );
  };

  return (<Datagrid bulkActionButtons={false} sx={{
    "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
    "& .RaDatagrid-expandIcon": { color: "#fff" },
    "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
    "& .RaDatagrid-headerCell": {
      fontWeight: "bold",
      fontSize: 12,
    },
    "& .RaDatagrid-table": {
      fontSize: 12,
    },
    "& .MuiToggleButton-root.Mui-selected": {
      fontWeight: 700,
    },
  }}>
    {/* <TextField source="id" />
        <TextField source="LetterKey" /> */}
    <TextField source="TemplateName" label={"Notification Name"} />
    {/* <TextField source="Subject" /> */}
    {/* <TextField source="Body" /> */}
    <DateField source="StartDate" label={"Start Date"} />
    <TextField source="StartTime" label={"Start Time"} />
    <DateField source="EndDate" label={"End Date"} />
    <TextField source="EndTime" label={"End Time"} />
    {/* <FunctionField 
  
        label={"Status"}
       source="Status"
        render={(record) =>
          record.Active ? <ActiveBtn /> : <DeActiveBtn />
        }
        /> */}
    {/* <FunctionField
            label={"Status"}
            source=" Active"
            render={(record) => {
                return (
                    <MuiButton
                        sx={{ width: "80%" }}
                        variant="contained"
                        disableElevation={true}
                        style={bgStyle(record["Active"])}
                    >
                      {record["Active"]}
                    </MuiButton>
                );
            }}
        /> */}

    <FunctionField
      sortByOrder="DESC"
      sortBy="Status"
      label={"Status"}
      render={(record) =>
        record.Active == 1 ? <ActiveBtn /> : <DeActiveBtn />
      }
    />
    {/* <FunctionField
            label={"Action"}
           
            render={(record) => {
                return (
                  <MuiButton
                  color="error"
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmDelete(true)
                }}
                  startIcon={<DeleteIcon />}
                  variant="text"
                  sx={{textTransform:"capitalize"}}
                >
                  
                </MuiButton>
                );
            }}
        />
         <Confirm
       isOpen={confirmDelete}
       // loading={isLoading}
       sx={{
         "& .RaConfirm-iconPaddingStyle": { display: "none" },
       }}
       title="Confirm"
       content={"Are you sure you want to delete this notification?"}
       // onConfirm={() =>  handleDeleteDocument(doc_id)}
       onClose={() => {
         // setOtherincomeNumber(0);
         setConfirmDelete(false);
       } } onConfirm={function (event: React.MouseEvent<Element, MouseEvent>): void {
         throw new Error("Function not implemented.");
       } }                    /> */}
    {/* <FunctionField
      render={(record) => (
        <DeleteWithConfirmButton redirect={false} translateOptions={{ name: "Notification", id: record.TemplateName }} confirmContent="You will not be able to recover this record. Are you sure?" />

      )} /> */}
    <FunctionField
      render={(record) => {
        return (
          <>

            <Button
              label="Delete"
              onClick={() => {

                setSelectedId(record?.id);
                setSelectedTemplate(record?.TemplateName);
                setShowConfirm(true)
              }} // Show confirmation dialog
              style={{ color: "red" }}

            >
              <DeleteIcon style={{ color: "red" }} />
            </Button>

            <Confirm
              isOpen={showConfirm}
              title={`Delete Notification ${selectedTemplate}`}
              content="You will not be able to recover this record. Are you sure?"
              onConfirm={() => handleDelete(selectedId)}
              onClose={() => setShowConfirm(false)} // Close the dialog if canceled
            />
          </>
        );
      }}
    />

    <FunctionField
      label={""}
      render={(record) => (
        // <Box display={"flex"}>
        <Box display={"flex"} sx={{ width: "80px" }}>
          {/* <CopyLinkHoverDropdown record={record} /> */}
          <IconButton
            aria-label="view details"
            color="primary"
            sx={{
              py: 0,
            }}
            onClick={() => {

              navigate(
                `/ats/adminnotificatio/updateNotification/${record.id}`,
                //   { state: { isAdding: false, thirdPartyAppId: 1 } }
              )
            }}
            className="show_on_hover"
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      )}
    />


  </Datagrid>


  )
}
export default SystemNotification;