import React, { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import DialogContent from "@mui/material/DialogContent";
import AddIcon from "@mui/icons-material/Add";
import DialogTitle from "@mui/material/DialogTitle";
import { RxCross2 } from "react-icons/rx";
import DialogActions from "@mui/material/DialogActions";
import { FiMoreVertical, FiX } from "react-icons/fi";
import * as Yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { Box } from "@mui/material";
import save_icon from "../../../assets/images/save_icon.svg";
import TextEditor from "../../common/InputComponents/TextEditor";
import { useNotify, useDataProvider, useCreate, useRefresh } from "react-admin";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Backdrop,
  Typography,
  Button as MuiButton,
} from "@mui/material";
import { JSONTree } from "react-json-tree";

export default function AddNotesModal({
  record,
  //handleShowNotes
}) {
  const methods = useForm();
  const [noteText, setNoteText] = useState("");
  const [openAddNotesDialog, setOpenAddNotesDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { id, appType } = useParams();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenAddNotes = () => {
    setOpenAddNotesDialog(true);

    handleCloseMenu();
  };

  const handleCloseAddNotes = () => {
    setOpenAddNotesDialog(false);
  };
  const handleTextChange = (event) => {
    setNoteText(event.target.value);
  };

  const [create, { isLoading: loading1, error }] = useCreate();
  const AddNote = (formValue) => {
    console.log(formValue, "rty");
    const value = record;
    const formData = {
      subscriberid: id,
      ApplicationNumber: value.AppID,
      ApplicantID: value.id,
      Notes: formValue?.noteText,
      NotesCategoryID: "1",
    };
    create(
      "addNotes",
      { data: formData },
      {
        onSuccess: (data) => {
          notify(`Notes has been added succesfully`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setOpenAddNotesDialog(false);
          methods.reset();
          refresh();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        sx={{ mx: 1 }}
        variant="outlined"
        type="submit"
        onClick={handleOpenAddNotes}
        style={{ marginLeft: "1px" }}
      >
        Add
      </Button>
      <Dialog open={openAddNotesDialog} onClose={handleCloseAddNotes}>
        <DialogTitle
          sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
          id="customized-dialog-title"
        >
          Add New Note
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseAddNotes}
          sx={{
            position: "absolute",
            right: 6,
            top: 1,

            color: (theme) => theme.palette.grey[500],
          }}
        >
            <span style={{fontSize:"12px"}}>Dismiss</span>
          {/* <CloseIcon style={{ fontSize: "12px" }} /> */}
        </IconButton>
        <DialogContent>
          {/* <div className="note-editor">
            <textarea
              placeholder="Enter your note here..."
              rows={5}
              className="textarea"
              value={noteText}
              onChange={handleTextChange}
            />
            <button
              className="add-button"
              onClick={() => {
                AddNote(record);
              }}
              onSubmit={handleCloseAddNotes}
            >
              Add Note
            </button>
          </div> */}

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(AddNote)}>
              <div className="row">
                <div className="col-sm-12">
                  <TextEditor
                    label="Notes"
                    source={`noteText`}
                    placeholder="Start Typing here..."
                    labelClass={"lable-title"}
                    // onChange={handleTextChange}
                  />
                </div>
              </div>
              <Box
                display={"flex"}
                justifyContent={"end"}
                style={{ marginTop: "10px" }}
              >
                <Button
                  startIcon={<RxCross2 style={{ fontSize: 14 }} />}
                  sx={{ mx: 1 }}
                  onClick={() => {
                    handleCloseAddNotes();
                  }}
                  onSubmit={handleCloseAddNotes}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={<img src={save_icon} alt="save icon" />}
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                  disabled={loading1} // Disable button if loading or already disabled
                >
                  Save
                </Button>
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
