import React from "react";
import { Button as MuiButton, CircularProgress } from "@mui/material";
import ResendIcon from '../../assets/images/resend.svg';
import {
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
  useGetList,
} from "react-admin";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ResendInviteLogs = ({ record, ApplicationNumber, subscriberId }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { id } = useParams();
  const aspNetUserId = useSelector((state: any) => state?.users?.aspNetUserId);
 
  // call usecreate
  const [create, { isLoading, error }] = useCreate();
  // form on submit handler
  const ResendInviteHandler = (value) => {
 
    const formData = {
      subscriberid: record?.SubscriberID,
      applicationnumber: record?.ApplicationNumber,
      applicantid: record?.ApplicantID,
      //   userid: "f60913c7-bddd-4005-b3c5-b58e7f1d5a6d",
      userid: aspNetUserId,
      email: record?.InvitationEmail,
    };
    create(
      "resendinvite",
      { data: formData },
      {
        onSuccess: (data) => {
          notify(`Invitation has been send`, { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
          refresh();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }}),
      }
    );
  };
  if (isLoading)
    return (
      <>
        <div className="d-flex justify-content-center align-items-center">
          <CircularProgress size="30px" />
        </div>
      </>
       );
 return (
    <>
    
   {/* {(record?.ApplicantStatus ==="Invited" || record?.ApplicantStatus === "In Progress") &&
      ( */}
      <MuiButton
        sx={{
          ".MuiButton-startIcon": { paddingLeft: "10px" },
          minWidth: "80px",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          textTransform: "capitalize",
        }}
        onClick={ResendInviteHandler}
        startIcon={<img src={ResendIcon} alt="" />}
        variant="text"
        color="primary"
      >
        Resend Invite
      </MuiButton>
      {/* )} */}
    </> 
  );
};

export default ResendInviteLogs;
