import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Card } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Datagrid,
  FunctionField,
  NumberField,
  useCreate,
  useGetList,
  useNotify,
  useRefresh,
  useStore,
  useUpdate,
} from "react-admin";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
// import "../../App.css";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Backdrop,
  Typography,
  Button as MuiButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { JSONTree } from "react-json-tree";
import moment from "moment";
import { useFieldArray } from 'react-hook-form';
import { Delete, Save } from "@mui/icons-material";
import InputField2 from "../../common/InputComponents/InputField2";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import ToggleButtons from "../../common/InputComponents/ToggleButtons";
const ApplicationSettingsPreAuth = ({tabClick}) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const toggleButton = [
    {
      value: false,
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: true,
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const { id } = useParams();
  const [create] = useCreate();
  const DefaultAns = [
    {
      Option: "Yes",
      id: 1,
    },
    {
      Option: "No",
      id: 2,
    },
    {
      Option: "None",
      id: 3,
    },
  ];

  const notify = useNotify();
  const { data: getApplicationDetailsQueAns, isLoading: loadingrole } =
    useGetList("getApplicationDetailsQueAns", {
      filter: {
        SubscriberID: switchSubscriberObj?.value,
        profileId: id,
        rolename: tabClick,
      },
    });
  
  const validationSchema = yup.object().shape({
    Questions: yup.array().of(
      yup.object().shape({
        Question: yup.string().required('Question is required'),
        QualifyAnswer: yup.object().shape({
          label: yup.string().required('Qualify Answer Value is required').typeError('Qualify Answer Value is required'),
          value: yup.string().required('Qualify Answer Value is required').typeError('Qualify Answer Value is required'),
        }).typeError('Qualify Answer Value is required'),
        DefaultQuestion: yup.object().shape({
          label: yup.string().required('Default Question Value is required').typeError('Default Question Value is required'),
          value: yup.string().required('Default Question Value is required').typeError('Default Question Value is required'),
        }).typeError('Default Question Value is required'),
        Active: yup.boolean().required('Status is required'),
      })
    ),
  });

  const methods = useForm({
    defaultValues: {
      Questions: [
        { ID: 0, id: 0, Question: '', QualifyAnswer: '', DefaultQuestion: '', Active: true }
      ]
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  const { control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Questions',
  });

  useEffect(() => {
    methods.reset({ Questions: getApplicationDetailsQueAns?.map(item => ({ ...item, DefaultQuestion: { value: item.DefaultQuestion, label: item.DefaultQuestion }, QualifyAnswer: { value: item?.QualifyAnswer, label: item.QualifyAnswer } })) })
  }, [getApplicationDetailsQueAns])


  const onSubmit1 = (value) => {
    console.log(value, "onSiubmit")
    const addData = {
      ...value,
      Questions: value.Questions.map(item => ({ ...item, DefaultQuestion: item?.DefaultQuestion?.value, QualifyAnswer: item?.QualifyAnswer?.value })),
      PreauthEnable: 1,
      RoleName: tabClick,
      ProfileID: Number(id),
      SubscriberID: parseInt(switchSubscriberObj?.value),

    };

    console.log(addData, "dump")
    create(
      `addupdatepreauthquestions`,
      { data: addData },
      {
        onSuccess: (data) => {
          notify(
            `update Pre-auth questions successfully.`,

            {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          // refresh();
          // methods?.reset();

        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  
  return <>
    <p>Pre-authorization questions</p>
    <br />
    <Card>
      <div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit1)}>
            {/* <JSONTree data={methods.watch()} /> */}
            {fields.map((item, index) => (<div className="row p-4" key={item.id}>
              {/* <div className="col-12">
                            {index+1}. {methods.watch(`Questions.${index}.Questions`)}
                          </div> */}
              <div className="col-5">
                <InputField2
                  label="Question"
                  source={`Questions.${index}.Question`}
                  placeholder="Select"
                  labelClass={"lable-title"}
                  // handleOnChange={handleChange}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-2">
                <InputSelectField
                  label="Qualify Answer"
                  source={`Questions.${index}.QualifyAnswer`}
                  options={
                    DefaultAns?.map((item, index) => ({
                      label: item?.Option,
                      value: item?.Option,
                    }))}


                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
              </div>
              <div className="col-2">
                <InputSelectField
                  label="Default Selection"
                  source={`Questions.${index}.DefaultQuestion`}
                  options={
                    DefaultAns?.map((item, index) => ({
                      label: item?.Option,
                      value: item?.Option,
                    }))
                  }

                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
              </div>
              <div className="col-2">
                <ToggleButtons
                  toggleButton={toggleButton}
                  label="Active"
                  labelClass={"lable-title"}
                  source={`Questions.${index}.Active`}
                  // handleOnChange={(e) =>
                  //   handleChangeQue(e)
                  // }
                  onClickHandler={(event) =>
                    event.stopPropagation()
                  }
                />
              </div>
              <div className="col-1">
                <br />
                <Button startIcon={<Delete />} onClick={() => remove(index)} variant="outlined" color="error" >Delete</Button>
              </div>
            </div>))
            }
            <div className="row my-2">
              <div className="col-9"></div>
              <div className="col-3 d-flex"><Button
                startIcon={<AddIcon />}
                sx={{ mx: 1 }}
                variant="outlined"
                type="button"
                onClick={() => append({ ID: 0, id: 0, Question: '', QualifyAnswer: '', DefaultQuestion: '', Active: true })}
              >
                Add Question
              </Button>
                <Button
                  type="submit"
                  startIcon={<Save />}
                  sx={{ mx: 1 }}
                  variant="contained"
                >
                  Save Questions
                </Button>
              </div>
            </div>

          </form>
        </FormProvider>
      </div>
    </Card>
  </>
};
export default ApplicationSettingsPreAuth;
