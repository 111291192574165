// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Suspense, useContext, useEffect } from "react";
import "../../App.css";
import {
  ListBase,
  Datagrid,
  DateField,
  FunctionField,
  SearchInput,
  TextField,
  useListContext,
  useRecordContext,
  useGetList,
  ListContextProvider,
  useCreate,
} from "react-admin";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Button as MuiButton,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import { TableHead, TableRow, TableCell, Checkbox } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import { DatagridBody, RecordContextProvider } from "react-admin";
import { useStore } from "react-admin";
import { useParams, useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import ResendShareAppReport from "./ResendShareAppReport";
import { JSONTree } from "react-json-tree";

// export const ToogleFilterInboxType = (props) => {
//   const { setFilters, filterValues, displayedFilters } = useListContext();
//   useEffect(() => {
//     if (!filterValues.app_invitation_type) {
//       setFilters({ app_invitation_type: "1" }, ["app_invitation_type"]);
//     }
//   }, []);

//   return (
//     <>
//       {/* <JSONTree data={filterValues}/> */}
//       <ToggleButtonGroup
//         sx={{ paddingBottom: 0.5, "& .Mui-selected": { fontWeight: 700 } }}
//         size="small"
//         value={filterValues.app_invitation_type}
//         color="primary"
//         exclusive
//         onChange={(
//           event: React.MouseEvent<HTMLElement>,
//           newAlignment: string
//         ) => {
//           if (newAlignment) {
//             setFilters({ app_invitation_type: newAlignment }, [
//               "app_invitation_type",
//             ]);
//           }
//         }}
//         aria-label="Inbox"
//       >
//         <ToggleButton value={"1"} aria-label="Application">
//           Application
//         </ToggleButton>
//         <ToggleButton value={"2"} aria-label="Docupload">
//           Docupload
//         </ToggleButton>
//         <ToggleButton value={"3"} aria-label="Docupload">
//           Guest Card
//         </ToggleButton>
//       </ToggleButtonGroup>
//     </>
//   );
// };

// export const FilterListInbox = (props) => {
//   return (
//     <>
//       {/* <Button size='large' variant='outlined' startIcon={} label='' onClick={() => { }} /> */}
//       <IconButton
//         sx={{
//           border: "1px solid",
//           borderRadius: " 5px",
//           padding: "6px",
//           marginLeft: "-10px",
//           marginBottom: "4.4px",
//         }}
//         size="medium"
//         aria-label="delete"
//       >
//         <FilterListIcon />
//       </IconButton>
//     </>
//   );
// };

// export const filters = [
//   <ToogleFilterInboxType source="app_invitation_type" alwaysOn />,
//   <SearchInput
//     variant="outlined"
//     sx={{ backgroundColor: "#fff" }}
//     placeholder="Search"
//     InputLabelProps={{
//       shrink: true,
//     }}
//     source="q"
//     alwaysOn
//   />,
// ];

// export default function BasicPopover() {
//   const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
//     null
//   );

//   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? "simple-popover" : undefined;

//   return (
//     <div>
//       <IconButton
//         color="warning"
//         style={{ color: "#F9BB04" }}
//         size="small"
//         aria-label="info"
//         aria-describedby={id}
//         onClick={handleClick}
//       >
//         <InfoIcon style={{ fontSize: "14" }} />
//       </IconButton>
//       <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "left",
//         }}
//       >
//         <Typography sx={{ p: 2 }}>This is the Rent to Income Ratio.</Typography>
//       </Popover>
//     </div>
//   );
// }

const DatagridTableHeaderCell = ({ child }) => {
  const { sort, setSort } = useListContext();
  const [sortStatus, SetSortStatus] = React.useState(false);
  const sortBy = child.props.sortBy || child.props.source;
  return (
    <>
      {/* !sort.order?'ASC':sort.order=='ASC'?'DESC':'ASC' */}
      <Box display={"flex"} alignItems={"center"} justifyContent={"start"}>
        <span style={{ padding: "0px 2px" }}>
          {sortBy === sort.field ? (
            sort.order == "ASC" ? (
              <ArrowUpwardIcon style={{ fontSize: 18 }} />
            ) : (
              <ArrowDownwardIcon style={{ fontSize: 18 }} />
            )
          ) : null}
        </span>
        <span
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            color: sortBy === sort.field ? "#000" : "#909090",
          }}
          onClick={() => {
            sortBy && setSort({ field: sortBy, order: "ASC" });
          }}
        >
          {child.props.label || child.props.source}
        </span>
        {/* <Button size='small'  label={child.props.label || child.props.source} endIcon={} /> */}
        {/* {(child.props.label || child.props.source) == "RI%" && <BasicPopover />} */}
      </Box>
    </>
  );
};

const DatagridHeader = (props) => {
  const { children, isRowSelectable, isRowExpandable } = props;
  return (
    <TableHead
      sx={{
        height: 50,
        backgroundColor: "#FFFFFF",
        border: "0.5px solid #E0E0E0",
      }}
    >
      <TableRow>
        {isRowSelectable && <TableCell></TableCell>}
        {/* empty cell to account for the select row checkbox in the body */}
        {React.Children.map(children, (child) => {
          if (child)
            return (
              <TableCell sortDirection="asc" key={child.props.source}>
                <DatagridTableHeaderCell child={child} />
              </TableCell>
            );
        })}
        {/* {isRowExpandable && <TableCell></TableCell>} */}
      </TableRow>
    </TableHead>
  );
};

const MyDatagridRow = (props) => {
  const {
    record,
    id,
    onToggleItem,
    children,
    selected,
    selectable,
    isRowExpandable,
    isRowSelectable,
    expand,
  } = props;
  const [open, setOpen] = useStore("expandedTable", 0);
  const [tempOpen, setTempOpen] = React.useState(false);
  return (
    <RecordContextProvider value={record}>
      <TableRow className={props.className}>
        {/* first column: selection checkbox */}
        {isRowSelectable && (
          <TableCell padding="none">
            <Checkbox
              disabled={!selectable}
              checked={selected}
              onClick={(event) => onToggleItem(id, event)}
            />
          </TableCell>
        )}
        {/* data columns based on children */}
        {React.Children.map(children, (field) => {
          if (field)
            return (
              <TableCell key={`${id}-${field.props.source}`}>{field}</TableCell>
            );
        })}
      </TableRow>
    </RecordContextProvider>
  );
};

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow {...props} />} />
);
const MyDatagrid = (props) => (
  <Datagrid {...props} body={<MyDatagridBody {...props} />} />
);
export const circularBtn = {
  width: 40,
  height: 40,
  borderRadius: 50,
  margin: "0px 40px",
};

const MyList = (props) => {
  const { children, actions, filters, title, ...rest } = props;
  return (
    <ListBase {...props}>
      <Card>{children}</Card>
      {/* <Pagination /> */}
    </ListBase>
  );
};

export const GetLandlordThirdPartyAgentDetail = (props) => {
  console.log("🚀 ~ file: GetLandlordThirdPartyAgentDetail.tsx:286 ~ GetLandlordThirdPartyAgentDetail ~ props:", props)
  const { id } = useParams();
  //  record is a call for get application number
  const record = useRecordContext();
  //  filter: { url: filterData?.URL, SubscriberID: filterData?.SubscriberID },
  // get data
  const [filter, setFilter] = React.useState('');
  const [page, setPage] = React.useState(1);
  const perPage = 10;
  const sort = { field: 'id', order: 'ASC' };
  const { data, total, isLoading } =
    useGetList<any>("getlandlorddetail", {filter : { subscriberid: id || props.SubscriberId, ApplicationNumber: props?.ApplicationNumber }});
  const getlandlorddetail : any[] = data || [];
  const temptotal : number = total || 0;
  const filterValues = { q:filter,subscriberid: id || props.SubscriberId, ApplicationNumber: props?.ApplicationNumber  };
  const setFilters = filters => setFilter(filters.q);
  
  return (
    <>
    <ListContextProvider value={{ data:getlandlorddetail, total:temptotal, page, perPage, setPage, filterValues, setFilters, sort}}>
      <Card>
        <DatagridTable />
      </Card>
    </ListContextProvider>
    {/* <MyList
      actions={null}
      filter={{ subscriberid: id || props.SubscriberId, ApplicationNumber: props?.ApplicationNumber }}
      exporter={false}
      title={"Inbox"}
    >
      <DatagridTable />
    </MyList> */}
    </>
  );
};

const DatagridTable = (props) => {
  const {displayedFilters, filterValues, setFilters, hideFilter } =
    useListContext();
  const navigate = useNavigate();
  const [create, { isLoading: loading1, error }] = useCreate();
  const handleRevoke = (value) => {
    console.log(value,"dsxjhdg")
    const payload = {
      ID: value,
    };
    create(
      "revoke",
      { data: payload },
      {
        onSuccess: (data) => {
          notify(`Revoke succesfully`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          // setOpenAddNotesDialog(false);
          //  methods.reset();
            refresh();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    )
  }
  return (
    <MyDatagrid
      isRowExpandable={true}
      isRowSelectable={false}
      header={<DatagridHeader isRowExpandable={true} />}
      sx={{
        "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
        "& .RaDatagrid-expandIcon": { color: "#fff" },
        "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
        "& .RaDatagrid-headerCell": {
          fontWeight: "bold",
          fontSize: 12,
        },
        "& .RaDatagrid-table": {
          fontSize: 12,
        },
        "& .MuiToggleButton-root.Mui-selected": {
          fontWeight: 700,
        },
      }}
    >
      <FunctionField
        label="Name"
        sortBy="Name"
        sortByOrder="DESC"
        render={() => {
          return <TextField source="Name" label="Name" sortable={false} />;
        }}
      />
      <FunctionField
        label="Type"
        sortBy="Type"
        sortByOrder="DESC"
        render={() => {
          return <TextField source="Type" label="Type" sortable={false} />;
        }}
      />
      <FunctionField
        label="Date & "
        sortBy="Date"
        sortByOrder="DESC"
        render={() => {
          return (
            <DateField
              source="ModifiedOn"
              label="Date & "
              locales="en-US"
              sortable={false}
            />
          );
        }}
      />
      <FunctionField
        label="Expire Date"
        sortBy="Expire Date"
        sortByOrder="DESC"
        render={() => {
          return (
            <DateField
              source="ExipreDate"
              label="Expire Date"
              locales="en-US"
              sortable={false}
            />
          );
        }}
      />
      <FunctionField
        label="App Access"
        render={(record) => {
          return <>
            <MuiButton
                sx={{
                  ".MuiButton-startIcon": { paddingLeft: "10px" },
                  minWidth: "80px",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  textTransform: "capitalize",
                }}
                onClick={()=>handleRevoke(record?.ID)}
                // startIcon={<img src={ResendIcon} alt="" />}
                variant="text"
              >
                Revoke
              </MuiButton>
          </>;
        }}
      />
      {/* <FunctionField
        label="Status"
        render={() => {
          return <></>;
        }}
      /> */}
      {/* <FunctionField
        label={"Status"}
        source="ApplicationStatus"
        render={(record) => {
          return (
            <MuiButton
              sx={{ width: "100%" }}
              variant="contained"
              disableElevation={true}
              color={
                record.ScreeningDetails == "ConditionallyApproved"
                  ? "warning"
                  : record.ScreeningDetails == "Unacceptable"
                  ? "error"
                  : record.ScreeningDetails == "NoAssessment"
                  ? "primary"
                  : record.ScreeningDetails == ""
                  ? "inherit"
                  : "success"
              }
            >
              {record.ApplicationStatus == "Application In Progress"
                ? "In Progress"
                : record.ApplicationStatus}
            </MuiButton>
          );
        }}
      /> */}
      <FunctionField
        source="Action"
        render={(record) => {
          return (
            <>
              {/* <JSONTree data={filterValues.subscriberid}/> */}
              {/* <Box display={"flex"} justifyContent={"start"}> */}
              {/* {record?.ApplicantStatus == "Invited" && ( */}
            
              <ResendShareAppReport record={{...record,SubscriberId:filterValues.subscriberid}} />
              {/* )} */}
              {/* </Box> */}
            </>
          );
        }}
      />
    </MyDatagrid>
  );
};
