import React, { useEffect, useState, createContext } from "react";
import {
  Edit,
  TextInput,
  useGetIdentity,
  useGetList,
  useCreate,
  useGetOne,
  Resource,
  useNotify,
  NumberField,
  FunctionField,
  Datagrid,
  useListContext,
  useRecordContext,
  usePermissions,
  useStore
} from "react-admin";

import MenuItem from "@mui/material/MenuItem";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useForm, Controller, FormProvider } from "react-hook-form";
import addNote from "../../assets/images/addNote.svg";
import moment from "moment";

import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import InputSelectField from "../common/InputComponents/InputSelectField";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

import DialogActions from "@mui/material/DialogActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Menu from "@mui/material/Menu";

import DocumentAnalysisShow from "../DocumentAnalysis";
import List from "@mui/material/List";
import SaveIcon from "@mui/icons-material/Save";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import img1 from "../../assets/images/img1.svg";
import pencilIcon from "../../assets/images/pencilIcon.svg";
import overa from "../../assets/images/overa.svg";
import greenTick from "../../assets/images/greenTick.svg";
import redTick from "../../assets/images/redTick.svg";
import notifyImg from "../../assets/images/notifyImg.svg";
import doc1 from "../../assets/images/doc1.png";
import doc2 from "../../assets/images/doc2.png";
import doc3 from "../../assets/images/doc3.png";
import doc4 from "../../assets/images/doc4.png";
import dlt from "../../assets/images/dlt.svg";
import viewapp from "../../assets/images/viewapp.svg";
import viewdoc from "../../assets/images/viewdoc.svg";
import remove from "../../assets/images/remove.svg";
import archieve from "../../assets/images/archieve.svg";
import { RxCross2 } from "react-icons/rx";
import CancelAppSvg from "../../assets/images/CancelApp.svg";
import Transfer from "../../assets/images/Transfer.svg";
import mail from "../../assets/images/mail.svg";
import Shareapp from "../../assets/images/Shareapp.svg";
import greenStatus from "../../assets/images/greenStatus.svg";
import viewresult from "../../assets/images/viewresult.svg";
import flag from "../../assets/images/flag.svg";
import greenCircle from "../../assets/images/greenCircle.svg";
import dropdown from "../../assets/images/dropdown.svg";
import bluecheck from "../../assets/images/bluecheck.svg";
import AddCoapplicant from "./AddCoapplicant";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import Archive from "./Archive";
import TransferApp from "./TransferApp";
import SendEmailToApplicant from "./SendEmailToApplicant";
import ArchiveAplicant from "./ArchiveAplicant";
import ApplicantDetailBox from "./ApplicantDetailBox";
import { useDispatch, useSelector } from "react-redux";
import InputField2 from "../common/InputComponents/InputField2";

import { RootState } from "../../Store/store";
import { setUserRole, setUserTrnId } from "../../Store/Slice/usersSlice";
import {
  setAppFooter,
  setAppHeader,
  setisGuestCard,
  setisOpeningForDashboard,
} from "../../Store/Slice/applicationSettingSlice";
import { JSONTree } from "react-json-tree";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Button as MuiButton,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import WarningPrelimNotice from "./WarningPrelimNotice";
import CancelApp from "./CancelApp";
import ShareAppReport from "./ShareAppReport";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { ArrowBack } from "@mui/icons-material";
import { FiMoreVertical, FiX } from "react-icons/fi";
import { MdNoteAlt } from "react-icons/md";
import { RiHome6Line } from "react-icons/ri";
import { BiBookContent } from "react-icons/bi";
import { RiHome7Line } from "react-icons/ri";
import { AiOutlineLogin } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { RiSaveLine } from "react-icons/ri";
import Frame from "../../assets/images/Frame.svg";
import { RiDeleteBinLine } from "react-icons/ri";
import "../../App.css";
import { hi } from "date-fns/locale";
import YesNoRadioButton from "../../componets/common/InputComponents/YesNoRadioButton";
import { GetdeleteddocumentlistList } from "../DocuploadTableComponent/GetDeletedDocumentList";
import AlertDialogTable from "../DocuploadTableComponent/AlertDialogTable";
import VerticalDotsMenu from "../common/VerticalDotsMenu";
import AuditlogModal from "../DocumentAnalysis/Modals/AuditlogModal";
import MoveButton from "../DocumentAnalysis/components/MoveButton";
import SwitchApplicant from "./SwitchApplicant";
import { typesofUsers } from "../../config";
import { checkModuleAccess, isSuperAdmin } from "../../Functions/permissions";
import { FaRegNoteSticky } from "react-icons/fa6";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// const customStyle = {
//   backgroundColor: 'gray',
//    fontSize:'30%',
//   // Add other styles as needed
// };

const theme = createTheme({
  palette: {
    neutral: {
      main: "#fdba04",
      contrastText: "#fff",
    },
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
  }
}

// Update the CircularProgress color prop options
declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    neutral: true;
  }
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  const color = props.value === 100 ? "green" : "orange";
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      {/* Apply the calculated color to CircularProgress */}
      <CircularProgress
        variant="determinate"
        value={props.value}
        size="33px"
        sx={{ color: color }}
        thickness={4}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          border:
            props?.value === 100
              ? "0"
              : props?.value === 0
                ? "2px solid #E0E0E0"
                : "1px solid #E0E0E0",
        }}
      >
        {props.value === 100 ? (
          <CheckIcon style={{ color: "green" }} />
        ) : (
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            fontSize="10px"
          >{`${Math.round(props.value)}%`}</Typography>
        )}
      </Box>
    </Box>
  );
}

export const Applicant = ({
  trn_id,
  userRole = "Docuverus Super Admin",
  children,
}) => {
  const dispatch = useDispatch();
  const { id, appType } = useParams();
  useEffect(() => {
    dispatch(setUserTrnId(trn_id));
    dispatch(setUserRole(userRole));
    dispatch(setAppHeader(false));
    dispatch(setAppFooter(appType == "2" ? true : false));
    dispatch(setisOpeningForDashboard(appType == "2" ? false : true));

    return () => { };
  }, []);

  return <>{children}</>;
};

const GuestCard = ({
  trn_id,
  userRole = "Docuverus Super Admin",
  children,
}) => {
  const dispatch = useDispatch();

  const params = useParams();

  const location = useLocation();
  const getlocalStorageItem: any = localStorage.getItem(
    "stateRecord" + params?.applicationId
  );
  let locationState = JSON.parse(getlocalStorageItem);
  const data = location.state || locationState;
  const {
    data: applicantDatasub,
    isLoading: loading,
    refetch,
  } = useGetOne("getApplicantDetails", { id: trn_id }, { enabled: !!trn_id });
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setUserTrnId(trn_id));
    dispatch(setUserRole(userRole));
    dispatch(setAppHeader(true));
    dispatch(setAppFooter(true));
    dispatch(setisGuestCard(true));
    dispatch(setisOpeningForDashboard(false));
    return () => { };
  }, []);
  const logo = applicantDatasub?.office_logo || applicantDatasub?.property_logo ? applicantDatasub?.office_logo || applicantDatasub?.property_logo : applicantDatasub?.subscriber_logo;

  return (
    <>
      <div className="">
        <div className="home_content container-lg">
          <div className="application-wrap">
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center", // To center content vertically
                justifyContent: "left", // To center content horizontally
                flexDirection: "row",
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{
                  maxWidth: "50px",
                  height: "auto",
                  marginRight: "5px",
                  display: logo
                    ? "initial"
                    : "none", // Hide the entire image if the source is falsy
                }}
                onError={(e) => {
                  const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                  target.style.display = "none"; // Hide the broken image
                }}
              />

              {!loading && (
                <strong>
                  {applicantDatasub?.subscriber_details?.CompanyName}
                </strong>
              )}
            </div>
            <div className="application-head">
              <div>
                <Breadcrumbs>
                  <Typography sx={{ fontWeight: "bold" }}>

                    <button
                      title="Go Back"
                      style={{ color: "#2cc0e4" }}
                      className="btn btn-link"
                      onClick={() =>
                        navigate(
                          !location?.state
                            ? locationState?.from?.pathname
                            : -1
                        )
                      }
                    >
                      {" "}
                      <ArrowBack />
                    </button>

                    <span>Guestcard Details</span>
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div className="tab-content app-nav-tabcontent">
              <div className="tab-pane fade show active name1-wrap">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const MoveDocFileContext = createContext<any>(null);
const ApplicantDetailEdit = (props) => {
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const notify = useNotify();
  const location = useLocation();
  const params = useParams();
  const [moveFileState, setMoveFileState] = useState({ isOpen: false, docId: null, trnID: null, applicantID: null, source_index: null, source_name: null });
  const { permissions } = usePermissions();
  const [switchSubscriberObj, setSwitchSubscriberObj] = useStore('switchSubscriberName', { value: '', label: '', companyType: '' });
  console.log("switchSubscriberObj1", switchSubscriberObj)

  const getlocalStorageItem: any = localStorage.getItem(
    "stateRecord" + params?.applicationId
  );

  let locationState = JSON.parse(getlocalStorageItem);
  const searchParams = new URLSearchParams(location.search);
  const encodedData: any = searchParams.get("data");
  const methods = useForm();

  console.log("locationState 1", locationState)

  console.log("test one", encodedData)

  if (encodedData) {
    console.log("test one")
    locationState = JSON.parse((encodedData));
  }

  console.log("locationState 2", locationState)
  const data = location.state || locationState;

  // No changes need in this compoment as of now
  const trn_id = useSelector((state: RootState) => state.users.userTrnId);
  const { id, appType } = useParams();

  console.log("appType3", appType)

  const {
    data: applicantDatasub,
    isLoading: loading,
    refetch,
  } = useGetOne("getApplicantDetails", { id: trn_id }, { enabled: !!trn_id });

  const { data: applicantData, isLoading } = useGetList(
    "get_subscriber_inbox_detials",
    {
      filter: { ApplicationNumber: data?.id, subscriberid: id },
    }
  );

  const { data: getUserData } = useGetIdentity();
  const { data: CompanyType } = useGetOne("getfilterforinbox", {
    id: id || getUserData?.subscriberid,
  });
  console.log(CompanyType?.CompanyType, "CompanyType");
  const [toggleDetails, setToggleDetails] = useState(false);
  const [applicantData1, setApplicantData] = useState([]);
  const [deleteddocs, setdeleteddocs] = useState(false);

  const handleopendeleteddocs = () => {
    setdeleteddocs(true);
  };
  const handleclosedeleteddocs = () => {
    setdeleteddocs(false);
  };
  const handleTabRemove = () => {
    setTab(0);
  };
  const record = useRecordContext();
  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      ApplicationNumber: record?.id,
      Notice: "0",
      IsEmail: "0",
    },
  });

  console.log("data 2", id)
  console.log("data 3", applicantData)
  const filterDataRemove =
    applicantData &&
    applicantData?.length > 0 &&
    applicantData?.filter(
      (item) =>
      (item?.Active == true &&
        item?.ApplicantType !== "Applicant" &&
        (item?.ApplicantStatus == "Invited" ||
          item?.ApplicantStatus == "In Progress"))
    );

  const filterData =
    applicantData &&
    applicantData?.length > 0 &&
    applicantData?.filter(
      (item) =>
      (item?.Active == true &&
        item?.ApplicantType !== "Applicant")
    );

  console.log("filterData 2", filterData)

  const detailsHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggleDetails(event.target.checked);
  };
  const logo = applicantDatasub?.office_logo || applicantDatasub?.property_logo ? applicantDatasub?.office_logo || applicantDatasub?.property_logo : applicantDatasub?.subscriber_logo;

  React.useEffect(() => {
    if (appType == "1") {
      document.title = "Applicant Details";
    } else if (appType == "2") {
      document.title = "Doc Analysis Details";
    } else if (appType == "3") {
      document.title = "Guestcard Details";
    } else {
      document.title = "Applicant Details";
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => { };
  }, []);

  const processScreeningConditionalRendering = [
    "Invited",
    "Application In Progress",
  ];
  const {
    data: applicationData,
    error,
  } = useGetOne(
    "getapplicationinboxdetails",
    {
      id: data?.id,
    },
    { enabled: !!data?.id }
  );
  if (isLoading) return <>Is loading</>;

  if (applicantData)
    if (appType == "3")
      return (
        <>
          <GuestCard
            trn_id={applicantData[0].Trnid}
            userRole={getUserData?.userrole}
          >
            <DocumentAnalysisShow record={applicantData[0]} />
          </GuestCard>
        </>
      );
    else
      return (
        <>
          <MoveDocFileContext.Provider value={{ moveFileState, setMoveFileState }}>
            <MoveButton />
            <Dialog
              open={deleteddocs}
              onClose={handleclosedeleteddocs}
              fullWidth
              sx={{
                "& .MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper":
                  { maxWidth: 900 },
              }}
            >
              <DialogTitle
                sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
                id="customized-dialog-title"
              >
                Deleted documents
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleclosedeleteddocs}
                sx={{
                  position: "absolute",
                  right: 6,
                  top: 1,

                  color: (theme) => theme.palette.grey[900],
                }}
              >
                  <span style={{fontSize:"12px"}}>Dismiss</span>
                {/* <CloseIcon style={{ fontSize: "12px" }} /> */}
              </IconButton>
              <DialogContent
                onClick={handleopendeleteddocs}
                sx={{ margin: "5px 5px 5px 5px", color: "red" }}
              >
                <Resource
                  name="deletedDocument"
                  list={GetdeleteddocumentlistList}
                />
              </DialogContent>
            </Dialog>
            {/* <Header /> */}
            <div className="home_content">

              {appType == "1" && <ApplicantDetailBox appDetails={data} />}

              {/* style={{marginTop: appType !== "1" ? "69px" : ""}} */}
              <div className="application-wrap">
                {appType !== "1" && (
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center", // To center content vertically
                      justifyContent: "left", // To center content horizontally
                      flexDirection: "row",
                    }}
                  >
                    <img
                      src={logo}
                      alt="Logo"
                      style={{
                        maxWidth: "50px",
                        height: "auto",
                        marginRight: "5px",
                        display: logo
                          ? "initial"
                          : "none", // Hide the entire image if the source is falsy
                      }}
                      onError={(e) => {
                        const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                        target.style.display = "none"; // Hide the broken image
                      }}
                    />

                    {!loading && (
                      <strong>
                        {applicantDatasub?.subscriber_details?.CompanyName}
                      </strong>
                    )}
                  </div>
                )}
                <div role="presentation">
                  <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link underline="hover" color="inherit" href="/ats">
                      Dashboard
                    </Link> */}
                    {/* <Link underline="hover" color="inherit"onClick={()=>navigate(-1)}>
                      <ArrowBack />
                    </Link> */}
                    <Typography sx={{ fontWeight: "bold" }}>
                      {appType != "1" && (
                        <button
                          title="Go Back"
                          style={{ color: "#2cc0e4" }}
                          className="btn btn-link"
                          onClick={() =>
                            navigate(
                              !location?.state
                                ? locationState?.from?.pathname
                                : -1
                            )
                          }
                        >
                          {" "}
                          <ArrowBack />
                        </button>
                      )}
                      {/* {appType == "1" && <span>Applicant Details</span>} */}
                      {appType == "2" && <span>Doc Analysis Details</span>}
                      {appType == "3" && <span>Guestcard Details</span>}
                    </Typography>
                  </Breadcrumbs>
                </div>
                <fieldset disabled={getUserData?.userrole == typesofUsers.docuverusAdmin && !checkModuleAccess(permissions, "Dashboard", "Edit Application")}>
                  <div className="application-head">
                    <br />


                    {/* new condition for add and remove  ATS -> "ADD" & "Remove" Buttons Should not display once application gets submitted*/}
                    {/*  (data?.ApplicationStatus == "Invited" ||
                      data?.ApplicationStatus == "Application In Progress") */}

                    {/* <JSONTree data={isSuperAdmin(checkModuleAccess(permissions,"Doc Upload App","Add"),getUserData?.userrole )}/> */}

                    <div className="row  ml-auto">
                      {!(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && (
                        <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                          <div className="modal-sm">
                            {appType == "1" && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "Add"), getUserData?.userrole) && (
                              <AddCoapplicant record={data} buttonAdd />
                            )}
                            {appType == "2" && isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", "Add"), getUserData?.userrole) && (
                              <AddCoapplicant record={data} buttonAdd />
                            )}
                            {appType == "3" && isSuperAdmin(checkModuleAccess(permissions, "Guest Card App", "Add"), getUserData?.userrole) && (
                              <AddCoapplicant record={data} buttonAdd />
                            )}
                          </div>
                        </div>
                      )}


                      {
                        filterDataRemove &&
                        filterDataRemove?.length > 0 &&
                        !(filterDataRemove.length === 1 && filterDataRemove[0].ApplicantType === "Applicant") &&
                        !(getUserData?.userrole === typesofUsers.landlordThirdPartyAgent) && (
                          <>
                            {appType === "1" &&
                              isSuperAdmin(checkModuleAccess(permissions, "Inbox", "Remove"), getUserData?.userrole) && (
                                <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                                  <div className="modal-sm">
                                    <div className="tooltip-wrapper">
                                      <button>
                                        <img src={remove} alt="" />
                                        <span>Remove</span>
                                      </button>
                                      <div className="tooltip">
                                        <ul>
                                          {filterDataRemove?.map((application) =>
                                            application?.ApplicantType === "Applicant" ? (
                                              <SwitchApplicant
                                                record={applicantData}
                                                subscriberId={id}
                                                ApplicantRemove
                                              />
                                            ) : (
                                              <ArchiveAplicant
                                                record={application}
                                                buttonRemove
                                              />
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                            {appType === "2" &&
                              isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", "Remove"), getUserData?.userrole) && (
                                <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                                  <div className="modal-sm">
                                    <div className="tooltip-wrapper">
                                      <button>
                                        <img src={remove} alt="" />
                                        <span>Remove</span>
                                      </button>
                                      <div className="tooltip">
                                        <ul>
                                          {filterDataRemove?.map((application) =>
                                            application?.ApplicantType === "Applicant" ? (
                                              <SwitchApplicant
                                                record={applicantData}
                                                subscriberId={id}
                                                ApplicantRemove
                                              />
                                            ) : (
                                              <ArchiveAplicant
                                                record={application}
                                                buttonRemove
                                              />
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                            {appType === "3" &&
                              isSuperAdmin(checkModuleAccess(permissions, "Guest Card App", "Remove"), getUserData?.userrole) && (
                                <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                                  <div className="modal-sm">
                                    <div className="tooltip-wrapper">
                                      <button>
                                        <img src={remove} alt="" />
                                        <span>Remove</span>
                                      </button>
                                      <div className="tooltip">
                                        <ul>
                                          {filterDataRemove?.map((application) =>
                                            application?.ApplicantType === "Applicant" ? (
                                              <SwitchApplicant
                                                record={applicantData}
                                                subscriberId={id}
                                                ApplicantRemove
                                              />
                                            ) : (
                                              <ArchiveAplicant
                                                record={application}
                                                buttonRemove
                                              />
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </>
                        )
                      }

                      {filterData && filterData?.length >= 1 && !(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && (
                        <div className="col-lg-auto col-md-auto col-4 col-sm-3" style={{ marginTop: '-5px', paddingRight: "0px" }}>
                          <SwitchApplicant
                            record={applicantData}
                            subscriberId={id}
                            switchApplicantProps
                          />
                        </div>
                      )}


                      {
                        !(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && (
                          <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                            <div className="modal-sm">
                              {appType == "1" && CompanyType?.CompanyType == "Real Estate" && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "Transfer"), getUserData?.userrole) && (
                                <TransferApp record={data} buttonTransfer />
                              )}
                              {appType === "2" && CompanyType?.CompanyType == "Real Estate" && isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", "Transfer"), getUserData?.userrole) && (
                                <TransferApp record={data} buttonTransfer />
                              )}
                              {appType === "3" && CompanyType?.CompanyType == "Real Estate" && isSuperAdmin(checkModuleAccess(permissions, "Guest Card App", "Transfer"), getUserData?.userrole) && (
                                <TransferApp record={data} buttonTransfer />
                              )}
                            </div>
                          </div>


                        )}


                      {!(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                        {/* <div className="modal-sm">
                        <Archive record={data} buttonArchieve />
                      </div> */}
                        {appType === "1" && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "Archive"), getUserData?.userrole) && (
                          <div className="modal-sm">
                            <Archive record={data} buttonArchieve />
                          </div>
                        )}
                        {appType === "2" && isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", "Archive"), getUserData?.userrole) && (
                          <div className="modal-sm">
                            <Archive record={data} buttonArchieve />
                          </div>
                        )}
                        {appType === "3" && isSuperAdmin(checkModuleAccess(permissions, "Guest Card App", "Archive"), getUserData?.userrole) && (
                          <div className="modal-sm">
                            <Archive record={data} buttonArchieve />
                          </div>)}
                      </div>}
                      {(data?.ApplicationStatus == "Invited" ||
                        data?.ApplicationStatus == "Application In Progress" ||
                        data?.ApplicationStatus == "Submitted" ||
                        data?.ApplicationStatus == "Screening") && !(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && (
                          <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                            <div className="modal-sm">
                              <CancelApp record={data} buttonCancelApp navigateUser />
                            </div>
                          </div>
                        )}
                      {/* <JSONTree data={data?.Subscribertype == "Real Estate" }/>
                      <JSONTree data={!(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent)}/> */}



                      {/* Application View */}
                      {data?.SecondaryApp &&
                        data?.ApplicationStatus !== "Cancelled" &&
                        !data.Archived &&
                        appType == "1" && !(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && (
                          <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                            <div className="modal-sm">
                              <WarningPrelimNotice
                                resendPreliminaryNotice={data?.EmailStatus ? true : false}
                                vewDetailsMounting={true}
                              />
                            </div>
                          </div>
                        )}
                      {/* 
                  {data?.SecondaryApp &&
                    !data?.EmailStatus &&
                    appType == "1" && (
                      <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                        <div className="modal-sm">
                          <WarningPrelimNotice resendPreliminaryNotice={false} />
                        </div>
                      </div>
                    )} */}
                      {appType == "1" && !(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && (
                        <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                          <div className="modal-sm">
                            <AuditlogModal record={applicantData?.[0]} />
                          </div>
                        </div>
                      )}
                      {/* hide a  Share App/Report for now */}
                      {/* <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                <div className="modal-sm">
                  <button
                    type="button"
                    className=""
                    data-bs-toggle="modal"
                    data-bs-target="#invitemodal"
                  >
                    <img src={Shareapp} alt="" />
                    <span>Share App / Report</span>
                  </button>
                </div>
              </div> */}
                      {appType == "1" &&
                        applicationData?.data?.[0]?.ApplicationStatus !== "Invited" &&
                        applicationData?.data?.[0]?.ApplicationStatus !== "Application In Progress" &&
                        applicationData?.data?.[0]?.ApplicationStatus !== "Submitted" &&
                        applicationData?.data?.[0]?.ApplicationStatus !== "Review Pending" &&
                        applicationData?.data?.[0]?.ApplicationStatus !== "Cancelled" &&
                        applicationData?.data?.[0]?.ApplicationStatus !== "Archived" &&
                        switchSubscriberObj?.companyType === "Real Estate" &&
                        applicationData?.data?.[0]?.ApplicationStatus == "Screening" && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "Send App/Report"), getUserData?.userrole) && !(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && (
                          <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                            <div className="modal-sm">
                              <ShareAppReport record={data} buttonShareAppReport />
                            </div>
                          </div>
                        )}
                      {/* {appType == "1" && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "View Adverse Letter"), getUserData?.userrole) &&
                        applicationData?.data?.[0]?.ApplicationStatus !== "Invited" &&
                        applicationData?.data?.[0]?.ApplicationStatus !== "Application In Progress" &&
                        applicationData?.data?.[0]?.ApplicationStatus !== "Submitted" &&
                        applicationData?.data?.[0]?.ApplicationStatus !== "Review Pending" &&
                        applicationData?.data?.[0]?.ApplicationStatus !== "Cancelled" &&
                        applicationData?.data?.[0]?.ApplicationStatus !== "Completed-Approved" &&
                        applicationData?.data?.[0]?.ApplicationStatus !== "Archived" &&
                        applicationData?.data?.[0]?.ApplicationStatus == "Screening" && !(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && (
                          <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                            <div className="modal-sm">
                              <button
                                type="button"
                                className=""
                                onClick={() => { navigate('/ats/adverseletter', { state: { SubscriberID: id, ...applicationData?.data?.[0] } }) }}
                              >
                                <FaRegNoteSticky style={{ fontSize: "14px" }} />
                                <span>&nbsp; View Adverse Letter</span>
                              </button>
                               </div>
                          </div>
                        )} */}
                      {appType == "1" && !(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && (
                        <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                          <div className="modal-sm">
                            <SendEmailToApplicant
                              record={applicantData}
                              buttonSendMail
                            />
                          </div>
                        </div>
                      )}

                      {appType == "2" && !(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && (
                        <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                          <div className="modal-sm">
                            <button
                              onClick={handleopendeleteddocs}
                              type="button"
                              className=""
                              data-bs-toggle="modal"
                              data-bs-target="#deletedDocs"
                            >
                              <span>
                                <RiDeleteBinLine style={{ fontSize: "14px" }} />{" "}
                                Deleted docs
                              </span>
                            </button>
                          </div>
                        </div>
                      )}

                      {/* <div className="col-lg-auto col-md-auto col-4 col-sm-3">
                      <div className="modal-sm">
                      {appType == "1" && (<VerticalDotsMenu data={data}/>):(
                  <button
                  style={{
                    cursor: "not-allowed",
                    opacity: 0.5,
                    pointerEvents: "none",
                    color: "#909090",
                  }}
                >
                  <img
                    src={remove}
                    alt=""
                    style={{
                      cursor: "not-allowed",
                      opacity: 0.5,
                      pointerEvents: "none",
                      color: "#909090",
                    }}
                  />
                  <span>Remove</span>
                </button>
                )}

                      </div>
                    </div> */}
                      {appType == "1" && (
                        <VerticalDotsMenu
                          data={data}
                          applicantList={applicantData}
                        />
                      )}
                    </div>

                  </div>
                </fieldset>
                <div className="application-tabs">
                  <div className="nav-flexes">
                    <nav>
                      <div
                        className="nav nav-tabs app-nav-tabs"
                        id="nav-tab"
                        role="tablist"
                      >
                        {applicantData
                          ?.filter((item) => item.Active)
                          ?.map((item, index) => (
                            <button
                              key={item.AppID}
                              className={`nav-link ${tab === index ? "active" : ""
                                }`}
                              id={`nav-name-tab0${index}`}
                              data-bs-toggle="tab"
                              data-bs-target={"#nav-name" + index}
                              type="button"
                              role="tab"
                              aria-controls={"nav-name" + index}
                              aria-selected="true"
                              onClick={() => {
                                setTab(index);
                              }}
                            >
                              <div className="name1">
                                <h6
                                  style={{
                                    maxWidth: "150px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  title={`${item.FirstName} ${item.LastName}`}
                                >
                                  {`${item.FirstName} ${item.LastName}`}
                                </h6>

                                {/* <div className="progress-gr yellow">
                                <span className="progress-left">
                                  <span className="progress-bar"></span>
                                </span>
                                <span className="progress-right">
                                  <span className="progress-bar"></span>
                                </span>
                                <div className="progress-value">
                                  {item.ApplicationCompleted}
                                </div>
                              </div> */}
                                {appType == "1" && (
                                  <CircularProgressWithLabel
                                    value={
                                      item?.ApplicantStatus == "Invited"
                                        ? 0
                                        : Number(item?.ApplicationCompleted)
                                    }
                                  />
                                )}
                              </div>
                              <small style={{ textAlign: "left", display: "block", textDecoration: "none" }}>{item?.ApplicantType}</small>
                            </button>
                          ))}
                      </div>
                    </nav>

                    {appType == "1" && (
                      <div className="toggleWrapper-wrap">
                        <div className="toggleWrapper">
                          <input
                            type="checkbox"
                            className="dn "
                            style={{ display: "none" }}
                            id="dn"
                            onChange={detailsHandler}
                            checked={toggleDetails}
                          />
                          <label htmlFor="dn" className="toggle">
                            <span className="toggle__handler" />
                          </label>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className="tab-content app-nav-tabcontent"
                    id="nav-tabContent"
                  >
                    {applicantData
                      ?.filter((item) => item.Active)
                      ?.map((item, index) => (
                        <div
                          key={item.AppID}
                          className={`tab-pane fade show ${tab === index ? "active" : ""
                            } name1-wrap`}
                          id={`nav-name${index}`}
                          role="tabpanel"
                          aria-labelledby={`nav-name-tab${index}`}
                          tabIndex={index}
                        >
                          {/* Open guest card here */}
                          {/* {tab === index && ( */}
                          <fieldset disabled={getUserData?.userrole == typesofUsers.docuverusAdmin && !checkModuleAccess(permissions, "Dashboard", "Edit Application")}>
                            {appType == "1" ? (
                              <fieldset disabled={!(Number(item?.ApplicationCompleted) > 79)}>
                                <fieldset disabled={getUserData?.userrole == typesofUsers.landlordThirdPartyAgent || !isSuperAdmin(checkModuleAccess(permissions, "Inbox", "Edit"), getUserData?.userrole)}>
                                  <Applicant
                                    trn_id={item.Trnid}
                                    userRole={getUserData?.userrole}
                                  >
                                    <DocumentAnalysisShow
                                      toggleDetails={toggleDetails}
                                      record={item}
                                    />
                                  </Applicant>
                                </fieldset>
                              </fieldset>
                            ) : appType == "2" ? (
                              <fieldset disabled={getUserData?.userrole == typesofUsers.landlordThirdPartyAgent || !isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", "Edit"), getUserData?.userrole)}>
                                <Applicant
                                  trn_id={item.Trnid}
                                  userRole={getUserData?.userrole}
                                >
                                  <DocumentAnalysisShow
                                    toggleDetails={toggleDetails}
                                    record={item}
                                  />
                                </Applicant>
                              </fieldset>
                            ) : appType == "3" ? (
                              <fieldset disabled={getUserData?.userrole == typesofUsers.landlordThirdPartyAgent || !isSuperAdmin(checkModuleAccess(permissions, "Guest Card App", "Edit"), getUserData?.userrole)}>
                                <Applicant
                                  trn_id={item.Trnid}
                                  userRole={getUserData?.userrole}
                                >
                                  <DocumentAnalysisShow
                                    toggleDetails={toggleDetails}
                                    record={item}
                                  />
                                </Applicant>
                              </fieldset>
                            ) : null
                            }
                          </fieldset>

                          {/* )} */}
                          {/* End guest card here */}

                          {appType == "1" && (
                            <AlertDialogTable trn_id={item.Trnid} />
                          )}
                        </div>
                      ))}
                    {/* ---------------------- */}
                  </div>
                </div>
              </div>
            </div>
          </MoveDocFileContext.Provider>
        </>
      );

  return <>No data</>;
};

export default ApplicantDetailEdit;
