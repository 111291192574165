import { BooleanField, CreateButton, Datagrid, DateField, FunctionField, List, ListBase, ListGuesser, ListToolbar, ImageField , Pagination, Resource, TextField, useListContext, useNotify, useStore } from "react-admin"
import FilterChips from "../Ats/SuperAdminInbox/FilterChips";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Box,
    Link,
    ToggleButton,
    ToggleButtonGroup,
    Checkbox,
    Stack,
    Button as MuiButton
  } from "@mui/material";
  import IconButton from "@mui/material/IconButton";
  import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import NotificationFilter from "../AdminSystemNotification/NotificationFilter";
import ResourceFilter from "./ResourceFilter";
import CopyLinkHoverDropdown from "../Settings/PropertySetup/CopyLinkHoverDropdown";
import CopyLinkHoverDropdownUserSetup from "../Settings/UserSetup/CopyLinkHoverDropdownUserSetup";
import Copylinkforresource from "./Copylinkforresource";
export const AdminResources = () => {
    return <Resource name="getAdminResources" list={GetAdminResources} />
  }

  const MyListToolBar = () => {
    const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
      useListContext();
  
  
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    // Debounce the search function
    const debouncedSearch = debounce((value) => {
      // Perform your search logic here
      setFilters({ ...filterValues, q: value }, []);
    }, 500); // 500ms debounce delay
  
    const handleSearchChange = (event) => {
      const value = event.target.value;
      debouncedSearch(value);
    };
    const navigate = useNavigate();
  
    return (
      <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
        {/* Left side with title */}
        <Grid item xs={12} md={6}>
  
          <p style={{ font: "Open Sans", fontWeight: 600, fontSize: '18px' }}>Manage Resources</p>
  
  
  
        </Grid>
  
        {/* Right side with search input, filter button, and add button */}
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <div className="group-btn">
            {/* Search Input */}
            <MuiTextField
              placeholder="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              defaultValue={filterValues.q}
              onChange={handleSearchChange}
              variant="outlined"
              size="small"
            />
         <ResourceFilter/>
  
            <button
              type="button"
              onClick={() =>
                navigate(`/ats/adminresource/addresource`, {
                  state: { isAdding: true },
                })
              }
              className="blue-btn px-4"
            >
              <svg
                className="mr-r10"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.33337 4.83333V0.833334H5.66671V4.83333H9.66671V6.16667H5.66671V10.1667H4.33337V6.16667H0.333374V4.83333H4.33337Z"
                  fill="white"
                />
              </svg>
             Resource
            </button>
  
  
  
  
          </div>
        </Grid>
      </Grid>
    );
  };
  const MyList = ({ children, toolBar, ...props }) => {
    return (
      <ListBase
        {...props}
        perPage={100} // Number of items per page
      >
        {toolBar}
        <FilterChips excludeKeys={['app_invitation_type', 'status']} />
        {children}
  
        <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
        <br />
        <br />
        <br />
        <br />
      </ListBase>
    );
  };
  const ActiveBtn = () => {
    const myStyles = {
      display: "flex",
      width: "79px",
      padding: "8px 11px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "4px",
      background: "#E6FDF0",
    };
    const myTextStyles = {
      color: "var(--Success, #46A96F)",
      // textAlign: 'center',
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
    };
    return (
      <div style={myStyles}>
        <div style={myTextStyles}>Active</div>
      </div>
    );
  };
  const DeActiveBtn = () => {
    const myStyles = {
      display: "flex",
      width: "79px",
      padding: "8px 11px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "4px",
      background: "#FFF1F1",
    };
    const myTextStyles = {
      color: "var(--Success, #FF4848)",
      // textAlign: 'center',
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
    };
    return (
      <div style={myStyles}>
        <div style={myTextStyles}>Inactive</div>
      </div>
    );
  };
  
const GetAdminResources=()=>{
    const navigate = useNavigate();
    return(
        <>
         <MyList
                toolBar={<MyListToolBar />}
                sort={{ field: "id", order: "DESC" }}>
                    <Datagrid bulkActionButtons={false} sx={{
        "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
        "& .RaDatagrid-expandIcon": { color: "#fff" },
        "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
        "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,
        },
        "& .RaDatagrid-table": {
            fontSize: 12,
        },
        "& .MuiToggleButton-root.Mui-selected": {
            fontWeight: 700,
        },
    }}>
        <ImageField  source="ThumbNail" />
          
            <TextField source="Name" />
            <TextField source="Type" />
            <FunctionField
                    sx={{ marginLeft: 0 }}
                    sortByOrder="ASC"
                    sortBy={"Status"}
                    label={"Status"}
                    render={(record) => (
                        <>


                            <Box display={"flex"}>
                                {record.Active ? <ActiveBtn /> : <DeActiveBtn />}
                                <Box display={"flex"} sx={{ width: "100px" }}>
                                    <Copylinkforresource record={{ ...record }} />
                                    <IconButton
                                        aria-label="view details"
                                        color="primary"
                                        sx={{
                                            py: 0,
                                        }}
                                        onClick={() =>

                                            navigate(
                                                `/ats/adminresource/updateresource/${record?.id}`,
                                                { state: { isAdding: false } }
                                            )


                                        }
                                        className="show_on_hover"
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </>
                    )}

                />
         
           
          
        </Datagrid>
                </MyList>
        </>
    )
}
export default GetAdminResources;