import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";

import ResendIcon from "../../assets/images/resend.svg";

import InputSelectField from "./InputComponents/InputSelectField";

import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { Box, Button as MuiButton, Avatar } from "@mui/material";
import {
  useCreate,
  useGetOne,
  Resource,
  useNotify,
  useRecordContext,
  useStore,
  useRefresh,
  useGetIdentity,
  usePermissions,
} from "react-admin";

import { FiEye } from "react-icons/fi";

import { RiSaveLine } from "react-icons/ri";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import { FiMoreVertical, FiX } from "react-icons/fi";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate, useParams } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useStripeBarActions from "../../useStripeBarActions";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import { TextField, Button, FormLabel } from "@mui/material";
import { GetdeleteddocumentlistList } from "../DocuploadTableComponent/GetDeletedDocumentList";
import ProcessScreening from "../Ats/ProcessScreening";

import { RiDeleteBinLine } from "react-icons/ri";

import { FaRegNoteSticky } from "react-icons/fa6";

import DateComponent from "../Ats/DateComponent";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup"; // Import yupResolver

import InputFieldButtonGroup from "./InputComponents/InputFieldButtonGroup";
import { typesofUsers } from "../../config";
import { checkModuleAccess, isSuperAdmin } from "../../Functions/permissions";
import { JSONTree } from "react-json-tree";

export const MenuButtons = ({ title, onClickHandler, logo }) => {
  return (
    <div className="col-lg-auto col-md-auto col-4 col-sm-3 d-none d-md-block">
      <div className="modal-sm">
        <button
          type="button"
          className=""
          data-bs-toggle="modal"
          data-bs-target="#sendmodal"
          onClick={onClickHandler}
        >
          {logo} {/* <img src={mail} alt="" /> */}
          <span style={{ paddingLeft: "4px" }}>{title}</span>
        </button>
      </div>
    </div>
  );
};

export default function VerticalDotsMenu({ data, applicantList }) {
  //import
  const {
    anchorEl,
    handleOpen,
    handleClose,
    handlePrelimReport,
    handleRentalDecision,
    handleCloseRentalDecision,
    handleOpenLease,
    handleopendeleteddocs,
    handleOpenMenu,
    handleCloseMenu,
    deleteddocs,
    open,
    openRentalDecision,
    openPrelimReport,
    openLease,
    reset,
    appType,
    handleCloseLease,
    register,
    watch,
    handleClosePrelimReport,

    handleclosedeleteddocs,
    setValue,
    value,
  } = useStripeBarActions();

  const { id } = useParams();
  const notify = useNotify();
  const [create] = useCreate();

  const navigate = useNavigate();
  const refresh = useRefresh();

  // const checkboxValues = [
  //   {
  //     ID: "1",
  //     Reason: "Income to Rent Ratio",
  //     Selected: false,
  //   },
  //   {
  //     ID: "2",
  //     Reason: "Over Income Limit",
  //     Selected: false,
  //   },
  //   {
  //     ID: "3",
  //     Reason: "Report Score / Credit",
  //     Selected: false,
  //   },
  //   {
  //     ID: "4",
  //     Reason: "Landlord-Tenant Filing",
  //     Selected: false,
  //   },
  //   {
  //     ID: "5",
  //     Reason: "Employment History or Unverifiable Income",
  //     Selected: false,
  //   },
  //   {
  //     ID: "6",
  //     Reason: "Insufficient or Unsatisfactory Residency History",
  //     Selected: false,
  //   },
  //   {
  //     ID: "7",
  //     Reason: "Too many occupants",
  //     Selected: false,
  //   },
  //   {
  //     ID: "8",
  //     Reason:
  //       "Bankruptcy, Foreclosure action, Tax Lien, or other civil judgment",
  //     Selected: false,
  //   },
  //   {
  //     ID: "9",
  //     Reason: "Other",
  //     Selected: false,
  //   },
  //   {
  //     ID: "10",
  //     Reason: "Unacceptable criminal report",
  //     Selected: false,
  //   },
  // ];

  const processScreeningConditionalRendering = [
    "Invited",
    "Application In Progress",
  ];
  const {
    data: applicationData,
    isLoading,
    error,
  } = useGetOne(
    "getapplicationinboxdetails",
    {
      id: data?.id,
    },
    { enabled: !!data?.id }
  );
  console.log(applicationData, "applicationData");
  const validationSchema = Yup.object().shape({
    adverseAction: Yup.object()
      .shape({
        value: Yup.string().required("Required!").nullable(),
        label: Yup.string().nullable(),
      })
      .nullable()
      .required("Required!"),
    applicantName: Yup.array()
      .when("adverseAction", {
        is: (item) => (item?.value == "1" ? true : false),
        then: Yup.array().required("Required!"),
        otherwise: Yup.array().nullable(),

      }),
 
    LandlordStatus: Yup.object().required(),
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema), // Pass validationSchema to useForm
  });
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const funApprovedWithConditions = (formValues) => {
    setButtonIsDisabled(true);
    console.log(formValues, "win34");
    const formData = formValues?.LandlordStatus;

    create(
      "approvedWithConditions",
      {
        data: {
          ...formData,
          ApplicationNumber: Number(applicationData?.id),
          subscriberid: id,

          IsPDF:
            formValues?.adverseAction?.value == 2
              || formValues?.adverseAction?.value == 3
              ? true
              : false,
          IsPrint: formValues?.adverseAction?.value == 3 ? true : false,
          IsEmail: formValues?.adverseAction?.value == 1 ? true : false,
          ApplicantIDs:
            formValues?.applicantName?.length
              ? formValues.applicantName.map(item => Number(item.value))
              : [Number(applicantList?.[0]?.id)],
        },
      },
      {
        onSuccess: async (data) => {
          let message = "";
          if (data.IsEmail) {
            message = `Mail sent succesfully`;
          } else if (data.IsPrint) {
            message = `PDF successfully opened in new tab`;
          } else if (data.IsPDF) {
            message = `PDF downloaded successfully`;
          } else {
            message = "";
          }

          notify(message, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          refresh();
          methods?.reset();
          methods.setValue("adverseAction",[]);
          handleCloseRentalDecision();
          setButtonIsDisabled(false);
        },

        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  const menuButtons = (title, onClickHandler, logo) => {
    return (
      <div className="col-lg-auto col-md-auto col-4 col-sm-3 d-none d-md-block">
        <div className="modal-sm">
          <button
            type="button"
            className=""
            data-bs-toggle="modal"
            data-bs-target="#sendmodal"
            onClick={onClickHandler}
          >
            {logo} {/* <img src={mail} alt="" /> */}
            <span style={{ paddingLeft: "4px" }}>{title}</span>
          </button>
        </div>
      </div>
    );
  };

  const applicantNameOptions = applicantList?.map((item) => ({
    label: item?.FirstName,
    value: item?.id,
  }));
  console.log(applicantList?.[0]?.id, "applicantList");
  const adverseActionOptions = [
    { label: "Email", value: "1" },
    { label: "Download", value: "2" },
    { label: "Print", value: "3" },
  ];
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  useEffect(() => {
    if (methods.watch("LandlordStatus")?.LandlordStatus == "Approved") {
      methods.setValue(
        "adverseAction",
        adverseActionOptions.find((item) => item?.value == "1"),
        { shouldValidate: true }
      );
    }
  }, [methods.watch("LandlordStatus")]);

  useEffect(() => {
    if (applicantList && applicantList.length == 1) {
      methods.setValue("applicantName", applicantNameOptions, {
        shouldValidate: true,
      });
    }
  }, [methods.watch("LandlordStatus")]);
  const { data: getUserData } = useGetIdentity();
  const { permissions } = usePermissions();

  return (
    <>
      {processScreeningConditionalRendering?.includes(
        applicationData?.data?.[0]?.ApplicationStatus
      ) && !(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) ? null : (
        <ProcessScreening
          record={data}
          applicationData={applicationData}
          from={"verticalDotsMenu"}
          isDotsMenus={false}
        />
      )}


      {applicationData?.data?.[0]?.ApplicationStatus !== "Invited" &&
        applicationData?.data?.[0]?.ApplicationStatus !==
        "Application In Progress" &&
        applicationData?.data?.[0]?.ApplicationStatus !== "Submitted" &&
        applicationData?.data?.[0]?.ApplicationStatus !== "Archived"
        && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "Apply Rental Decision"), getUserData?.userrole)
        && menuButtons(
          "Apply Rental Decision",
          handleRentalDecision,
          <FaRegNoteSticky style={{ fontSize: "14px" }} />
        )}
      {/* {applicationData?.data?.[0]?.ApplicationStatus !== "Invited" &&
        applicationData?.data?.[0]?.ApplicationStatus !== "Application In Progress" &&
        applicationData?.data?.[0]?.ApplicationStatus !== "Submitted" &&
        menuButtons(
          "Generate Lease",
          handleOpenLease,
          <FaRegBuilding style={{ fontSize: "14px" }} />
        )} */}
      {!(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && menuButtons(
        "Deleted Docs",
        handleopendeleteddocs,
        <RiDeleteBinLine style={{ fontSize: "14px" }} />
      )}
      <div className="col-lg-auto col-md-auto col-4 col-sm-3 d-md-none">
        <MuiButton
          style={{
            marginLeft: "0px",
          }}
          sx={{
            ".MuiButton-startIcon": { paddingLeft: "0px" },
            minWidth: "60px",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            textTransform: "capitalize",
            padding: "0",
          }}
          onClick={handleOpenMenu} // Toggle the list visibility on button click
          startIcon={<FiMoreVertical />}
          variant="text"
          color="primary"
        ></MuiButton>
      </div>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {processScreeningConditionalRendering?.includes(
          applicationData?.data?.[0]?.ApplicationStatus
        ) ? null : (
          <ProcessScreening record={data} from={"verticalDotsMenu"} />
        )}
        {/* {appType === "1" &&
          applicationData?.data[0]?.State === "NJ" &&
          applicationData?.data?.[0]?.ApplicationStatus === "Screening" && (
            <MenuItem onClick={handlePrelimReport}>Prelim Notice</MenuItem>
          )} */}
        {/* {applicationData?.data?.[0]?.ApplicationStatus !== "Invited" &&
          applicationData?.data?.[0]?.ApplicationStatus !==
            "Application In Progress" &&
          applicationData?.data?.[0]?.ApplicationStatus !== "Submitted" && (
            <MenuItem onClick={handleRentalDecision}>
              Apply Rental Decision
            </MenuItem>
          )} */}

        {applicationData?.data?.[0]?.ApplicationStatus !== "Invited" &&
          applicationData?.data?.[0]?.ApplicationStatus !== "Application In Progress" &&
          applicationData?.data?.[0]?.ApplicationStatus !== "Submitted"
          //  && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "Rental Decision"), getUserData?.userrole)
          && (
            <>
              {appType === "1" && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "Apply Rental Decision"), getUserData?.userrole) && (
                <MenuItem onClick={handleRentalDecision}>
                  Apply Rental Decision
                </MenuItem>
              )}

            </>
          )}


        {applicationData?.data?.[0]?.ApplicationStatus !== "Invited" &&
          applicationData?.data?.[0]?.ApplicationStatus !==
          "Application In Progress" &&
          applicationData?.data?.[0]?.ApplicationStatus !== "Submitted" && (
            <MenuItem onClick={handleOpenLease}>Generate Lease</MenuItem>
          )}
        <MenuItem onClick={handleCloseMenu}>Logs</MenuItem>
        <MenuItem onClick={handleopendeleteddocs}>Deleted Docs</MenuItem>
      </Menu>
      {console.log(applicationData?.data?.[0]?.ApplicationStatus, "nj")}

      {/* Deleted docs */}
      <Dialog
        onClose={handleclosedeleteddocs}
        fullWidth
        open={deleteddocs}
        sx={{ "& .MuiDialog-paperFullWidth ": { maxWidth: "1000px" } }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
          id="customized-dialog-title"
        >
          Deleted documents
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleclosedeleteddocs}
          sx={{
            position: "absolute",
            right: 6,
            top: 1,

            color: (theme) => theme.palette.grey[800],
          }}
        >
          <span style={{ fontSize: "12px" }}>Dismiss</span>
          {/* <CloseIcon style={{ fontSize: "12px" }} /> */}
        </IconButton>
        <DialogContent
          onClick={handleopendeleteddocs}
          style={{ width: "1000px" }}
        >
          <Resource name="deletedDocument" list={GetdeleteddocumentlistList} />
        </DialogContent>
      </Dialog>

      {/* Apply Rental Decision */}
      <Dialog
        open={openRentalDecision}
        fullWidth
        sx={{ "& .MuiDialog-paperFullWidth ": { maxWidth: "800px" } }}
      >
        <DialogTitle
          sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
          id="customized-dialog-title"
        >
          Apply Rental Decision
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseRentalDecision}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span style={{ fontSize: "12px" }}>Dismiss</span>
          {/* <CloseIcon /> */}
        </IconButton>
        <DialogContent style={{ width: "800px", height: "400px" }}>
          <FormProvider {...methods}>
            <form
              id="checkboxForm"
              onSubmit={(event) => {
                event.preventDefault(); // Prevent default form submission
                methods.handleSubmit(funApprovedWithConditions)(event);
              }}
            >
              <div>
                <InputFieldButtonGroup source="LandlordStatus" />
              
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-sm-12 col-md-4 col-lg-4 mr-b10">
                    {methods.watch("LandlordStatus")?.LandlordStatus !=
                      "Approved" && <InputSelectField
                        label="How to send adverse action"
                        stopAutoFocus={true}
                        source={"adverseAction"}
                        options={adverseActionOptions}
                        // disable={
                        //   methods.watch("LandlordStatus")?.LandlordStatus ==
                        //   "Approved"
                        // }
                        labelAsteriskClass={"color-red"}
                        labelClass={"lable-title"}
                        requiredClass={{
                          fontSize: "70%",
                        }}
                      />}
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4 mr-b10">
                    {methods.watch("adverseAction")?.value === "1" &&  methods.watch("LandlordStatus")?.LandlordStatus !=
                        "Approved" && <InputSelectField
                      multiple={true}
                      label="Whom to send adverse action Notice"
                      source={"applicantName"}
                      stopAutoFocus={true}
                      options={applicantNameOptions}
                      labelAsteriskClass={"color-red"}
                      labelClass={"lable-title"}
                      requiredClass={{
                        fontSize: "70%",
                      }}
                    />}
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className=" d-flex align-items-center justify-content-end mr-t70">
                  <button
                    type="button"
                    className="blue-btn px-4"
                    style={{
                      height: "40px",
                    }}
                    onClick={handleCloseRentalDecision}
                  >
                    {" "}
                    <svg
                      className="mr-r10"
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 3.55732L8.3 0.257324L9.24267 1.19999L5.94267 4.49999L9.24267 7.79999L8.3 8.74266L5 5.44266L1.7 8.74266L0.757334 7.79999L4.05733 4.49999L0.757334 1.19999L1.7 0.257324L5 3.55732Z"
                        fill="white"
                      />
                    </svg>
                    &nbsp;Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={buttonIsDisabled}
                    className="blue-btn px-4 mx-2"
                    style={{
                      height: "40px",
                    }}
                  >
                    {" "}
                    <svg
                      className="mr-r10"
                      width="12"
                      height="9"
                      viewBox="0 0 12 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.66666 6.61463L10.7947 0.485962L11.738 1.42863L4.66666 8.49996L0.423996 4.2573L1.36666 3.31463L4.66666 6.61463Z"
                        fill="white"
                      />
                    </svg>
                    &nbsp;Submit
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>

      {/* Prelim Report */}
      <Dialog
        fullWidth
        open={openPrelimReport}
        onClose={handleClosePrelimReport}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Prelim Notice</DialogTitle>
        <DialogContent>
          <div className="cus-modal-body">
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <div className="row">
              <div className="col-auto">
                <div className="cus-input app-option-btn mr-b30">
                  <label htmlFor="" className="lable-title">
                    Pursuant to NJ law, the criminal report has been either
                    redacted from view or not processed and the applicant was
                    not asked about criminal history as part of their rental
                    application. notice?
                  </label>
                  <label htmlFor="" className="lable-title mr-t20">
                    In order to view/process the criminal report, you must
                    preliminarily notify the applicant that he/she/they are
                    approved/conditionally approved pending the results of their
                    criminal background report results and/or completion of a
                    secondary application. Have you provided or do you wish to
                    send this preliminary approval notice?
                  </label>
                  <div className="it-option" id="Notice">
                    <label htmlFor="notice1">
                      <input
                        type="radio"
                        id="notice1"
                        value="1"
                        {...register("Notice", { required: true })}
                      />
                      <span>Yes</span>
                    </label>
                    <label htmlFor="notice2">
                      <input
                        type="radio"
                        id="notice2"
                        value="0"
                        {...register("Notice", { required: true })}
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>
              </div>
              {watch("Notice") == "1" ? (
                <div className="col-auto">
                  <div className="cus-input app-option-btn mr-b30">
                    <label htmlFor="" className="lable-title">
                      Would you like to send preliminary notice to the applicant
                      now via E-mail?
                    </label>
                    <div className="it-option" id="IsEmail">
                      <label htmlFor="notice3">
                        <input
                          type="radio"
                          id="notice3"
                          value="1"
                          {...register("IsEmail", { required: true })}
                        />
                        <span>Yes</span>
                      </label>
                      <label htmlFor="notice4">
                        <input
                          type="radio"
                          id="notice4"
                          value="0"
                          {...register("IsEmail", { required: true })}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-sm-12">
                <div className="g-btm-btn d-flex align-items-center justify-content-end mr-t20">
                  <button
                    type="button"
                    className="blue-line-btn mr-r20"
                    onClick={() => {
                      handleClosePrelimReport();
                      reset();
                    }}
                  >
                    <svg
                      className="mr-r10"
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 3.55732L8.3 0.257324L9.24267 1.19999L5.94267 4.49999L9.24267 7.79999L8.3 8.74266L5 5.44266L1.7 8.74266L0.757334 7.79999L4.05733 4.49999L0.757334 1.19999L1.7 0.257324L5 3.55732Z"
                        fill="#2DC3E8"
                      />
                    </svg>
                    Cancel
                  </button>
                  <button type="submit" className="blue-btn">
                    <svg
                      className="mr-r10"
                      width="12"
                      height="9"
                      viewBox="0 0 12 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.66666 6.61463L10.7947 0.485962L11.738 1.42863L4.66666 8.49996L0.423996 4.2573L1.36666 3.31463L4.66666 6.61463Z"
                        fill="white"
                      />
                    </svg>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* general lease */}
      <Dialog open={openLease}>
        <DialogTitle
          sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
          id="customized-dialog-title"
        >
          Generate Lease
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseLease}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span style={{ fontSize: "12px" }}>Dismiss</span>
          {/* <CloseIcon /> */}
        </IconButton>
        <DialogContent style={{ width: "600px" }}>
          <div className="col-sm-12">
            <div className="details-card-row 2 mr-b30 d-flex align-items-center">
              <div className="static-d-info">
                <label>App ID</label>
                <h5>{data?.id ? data?.id : "NA"}</h5>
              </div>
              <div className="static-d-info">
                <label>App Date</label>
                <h5>
                  <DateComponent date={data?.AppDate} />
                </h5>
              </div>
              {/* <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">App Email</label>
                </div>
                <span>{data?.email}</span>
              </div> */}

              <div className="static-d-info">
                <label>Subscriber</label>
                <h5>
                  {data?.SubscriberName
                    ? data?.SubscriberName
                    : switchSubscriberObj?.label
                      ? switchSubscriberObj?.label
                      : "NA"}
                </h5>
              </div>

              <div className="static-d-info">
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <MuiButton
                    variant="contained"
                    disableElevation={true}
                    style={{
                      // color: "#127790",
                      fontSize: "12px",
                      // height: "32px",
                      textTransform: "capitalize",
                      cursor: "text",
                      fontFamily: "OpenSans-SemiBold",
                      padding: "5px 10px",
                      background:
                        applicationData?.data?.[0]?.ApplicationStatus ==
                          "Review Pending"
                          ? "#ecfbff"
                          : applicationData?.data?.[0]?.ApplicationStatus ==
                            "Application In Progress"
                            ? "#DDF8FF"
                            : applicationData?.data?.[0]?.ApplicationStatus ==
                              "Submitted"
                              ? "#B0EFFF"
                              : applicationData?.data?.[0]?.ApplicationStatus ==
                                "Invited"
                                ? "#E9E9E9"
                                : data?.ScreeningDetails == "ConditionallyApproved" ||
                                  data?.ScreeningDetails == "Conditionally Approved"
                                  ? "#FFF8E2"
                                  : data?.ScreeningDetails == "Unacceptable"
                                    ? "#F8ECE2"
                                    : data?.ScreeningDetails == "NoAssessment" ||
                                      data?.ScreeningDetails == "No Assessment"
                                      ? "#fff"
                                      : data?.ScreeningDetails == ""
                                        ? "inherit"
                                        : "#E6FDF0",

                      color:
                        applicationData?.data?.[0]?.ApplicationStatus ==
                          "Review Pending"
                          ? "#2dc3e8"
                          : applicationData?.data?.[0]?.ApplicationStatus ==
                            "Application In Progress"
                            ? "#1CA2C2"
                            : applicationData?.data?.[0]?.ApplicationStatus ==
                              "Submitted"
                              ? "#127790"
                              : applicationData?.data?.[0]?.ApplicationStatus ==
                                "Invited"
                                ? "#909090"
                                : data?.ScreeningDetails == "ConditionallyApproved" ||
                                  data?.ScreeningDetails == "Conditionally Approved"
                                  ? "#F9BB04"
                                  : data?.ScreeningDetails == "Unacceptable"
                                    ? "#FF4848"
                                    : data?.ScreeningDetails == "NoAssessment" ||
                                      data?.ScreeningDetails == "No Assessment"
                                      ? "#000000"
                                      : data?.ScreeningDetails == ""
                                        ? "inherit"
                                        : "#46A96F",
                    }}
                  >
                    {applicationData?.data?.[0]?.ApplicationStatus ==
                      "Application In Progress"
                      ? "In Progress"
                      : applicationData?.data?.[0]?.ApplicationStatus}
                  </MuiButton>
                </Box>
              </div>
              {data?.Score > 0 && (
                <div className="static-d-info">
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Avatar
                      sx={{
                        bgcolor:
                          data?.ScreeningDetails == "ConditionallyApproved" ||
                            data?.ScreeningDetails == "Conditionally Approved"
                            ? "#FFF8E2"
                            : data?.ScreeningDetails == "Unacceptable"
                              ? "#F8ECE2"
                              : data?.ScreeningDetails == "NoAssessment" ||
                                data?.ScreeningDetails == "No Assessment"
                                ? "#fff"
                                : data?.ScreeningDetails == ""
                                  ? "inherit"
                                  : "#E6FDF0",
                      }}
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "10px",
                        border:
                          data?.ScreeningDetails == "ConditionallyApproved" ||
                            data?.ScreeningDetails == "Conditionally Approved"
                            ? " 1px solid #F9BB04"
                            : data?.ScreeningDetails == "Unacceptable"
                              ? "1px solid #FF4848"
                              : data?.ScreeningDetails == "NoAssessment" ||
                                data?.ScreeningDetails == "No Assessment"
                                ? "1px solid #000000"
                                : data?.ScreeningDetails == ""
                                  ? "inherit"
                                  : "1px solid #46A96F",
                        color:
                          data?.ScreeningDetails == "ConditionallyApproved" ||
                            data?.ScreeningDetails == "Conditionally Approved"
                            ? "#F9BB04"
                            : data?.ScreeningDetails == "Unacceptable"
                              ? "#FF4848"
                              : data?.ScreeningDetails == "NoAssessment" ||
                                data?.ScreeningDetails == "No Assessment"
                                ? "#000000"
                                : data?.ScreeningDetails == ""
                                  ? "inherit"
                                  : "#46A96F",
                      }}
                    >
                      {data?.Score}
                    </Avatar>
                  </Box>
                </div>
              )}
            </div>
          </div>

          <Grid container spacing={2} columns={16}>
            <Grid item xs={8}>
              <FormLabel>
                &lt;Custom Lease Field &gt;
                <span className="required">*</span>
              </FormLabel>
              <TextField size="small" fullWidth />
            </Grid>
            <Grid item xs={8}>
              <FormLabel>
                &lt; Custom Lease Field&gt;
                <span className="required">*</span>
              </FormLabel>
              <TextField size="small" fullWidth />
            </Grid>
            <Grid item xs={8}>
              <FormLabel>Select Addendum</FormLabel>
              <TextField select fullWidth size="small" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant="contained">
            <RiSaveLine style={{ marginRight: "5px" }} />
            Save
          </Button>
          <Button autoFocus onClick={handleClose} variant="contained">
            <FiEye style={{ marginRight: "5px" }} />
            Preview
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
