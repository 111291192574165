import React, { Fragment, useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useLocation } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import InputField2 from "../../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import ToggleButtons from "../../common/InputComponents/ToggleButtons";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import GoogleAutoComplete from "../../common/InputComponents/GoogleAutocomplete";
import {
  Loading,
  useCreate,
  useGetIdentity,
  useGetList,
  useGetOne,
  useNotify,
  usePermissions,
  useRefresh,
  useStore,
} from "react-admin";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import { useSelector } from "react-redux";
import TextInputPhone from "../../DocumentAnalysis/components/inputComponents/TextInputPhone";
import FilePreview2 from "../../common/InputComponents/FilePreview2";
import InputFileUpload from "../../common/InputComponents/InputFileUpload";
import { useParams } from "react-router-dom";
import MultiEmailInput from "../../common/InputComponents/MultiEmailInput";
import CustomRadioButton from "../../common/InputComponents/CustomRadioButton";
import SettingsIcon from "@mui/icons-material/Settings";
import CopyUrls from "./CopyUrls";
import save_icon from "../../../assets/images/save_icon.svg";
import FloorPlanAndUnits from "./FloorPlanAndUnits";
import { CircularProgress } from "@mui/material";
import StripeAccountRadioButton from "./StripeAccountRadioButton";
import AlertForStripeModal from "./AlertForStripeModal";
import InputMaskField from "../../common/InputComponents/InputMaskFieid";
import formValidation from "../../../yupFormValidationRules/formValidator";
import { REACT_APPLICANT } from "../../../config";
import InputCurrencyField from "../../DocumentAnalysis/components/inputComponents/InputCurrencyFiled";
import { JSONTree } from "react-json-tree";
import { checkModuleAccess } from "../../../Functions/permissions";

const file_type = ["image/jpg", "image/jpeg", "image/png"];
const file_size = 4000 * 1024;

//component for Select integration rule type field
export const IntegrationRuleType = (props) => {
  const { methods, switchSubscriberObj } = props;

  const { data: getIntegrationSettingRule, isLoading } = useGetList(
    "integration-setting-rule",
    {
      filter: {
        subscriberID: switchSubscriberObj?.value,
        thirdPartyAppId: methods?.watch()?.third_party_integration?.value,
      },
    },
    {
      enabled:
        switchSubscriberObj?.value &&
          methods?.watch()?.third_party_integration?.value
          ? true
          : false,
      refetchOnWindowFocus: false,
    }
  );
const {permissions} =usePermissions();
  return (
    <>
      {isLoading ? (
        <div className="col-sm-4 mr-b30 mt-3 static-d-info d-flex align-items-center">
          <CircularProgress size="30px" />
          <h5>&nbsp; &nbsp; Loading...</h5>
        </div>
      ) : getIntegrationSettingRule && getIntegrationSettingRule?.length > 0 ? (
        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
          <InputSelectField
            label={`Select integration rule type`}
            source={`select_integration_rule_type`}
            options={getIntegrationSettingRule?.map((item) => ({
              value: item?.id,
              label: item?.ruleName,
            }))}
            labelAsteriskClass={"color-red"}
            labelClass={"lable-title"}
            requiredClass={{
              fontSize: "70%",
            }}
            disable={!checkModuleAccess(permissions, "Other Permissions", "Edit Properties/Offices")}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const PropertyDetails = () => {
  const navigate = useNavigate();
  const notify = useNotify();
  const refresh = useRefresh();
  const statusToggleButton = [
    {
      value: 0,
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: 1,
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const docUploadToggleButton = [
    {
      value: 0,
      label: "Disabled",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: 1,
      label: "Enabled",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const guestCardToggleButton = [
    {
      value: 0,
      label: "Disabled",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: 1,
      label: "Enabled",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { data: identity } = useGetIdentity();
  const { id } = useParams();
  let { state, pathname } = useLocation();
  state = state === null ? { type: (pathname.split("/")[3] === "officeSetup" ? "Office" : "Property") } : state;
  const [getThirdPartyAppsModifiedData, setGetThirdPartyAppsModifiedData] =
    useState<any>([]);
  // state for set a flag if data is enable/null of third Party Integration.
  const [isThirdPartyIntegration, setIsThirdPartyIntegration] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [isLoadingData, setLoadingData] = React.useState(true);
  // get Identification Data
  const { data: getStates, isLoading: isLoadingStates } = useGetOne(
    "getStates",
    {
      id: 1,
    },
    { refetchOnWindowFocus: false }
  );

  // get getApplicationProfile Data
  const { data: getApplicationProfile, isLoading: getPropertyDetailsLoading } = useGetOne(
    "application-profile",
    {
      id: switchSubscriberObj?.value,
    },
    {
      refetchOnWindowFocus: false,
      enabled: switchSubscriberObj?.value ? true : false,
    }
  );
  // get getHierarchyLevel Data
  const { data: getHierarchyLevel, isLoading: isLoadingHierarchyLevel } = useGetList(
    "hierarchy-levels-list",
    {
      filter: {
        subscriberID: switchSubscriberObj?.value,
      },
    },
    {
      refetchOnWindowFocus: false,
      enabled: switchSubscriberObj?.value ? true : false,
    }
  );
  // get getLeaseTemplate Data
  const { data: getLeaseTemplate, isLoading: isLoadingLeaseTemplate } = useGetOne(
    "lease-template",
    {
      id: switchSubscriberObj?.value,
    },
    {
      refetchOnWindowFocus: false,
      enabled: switchSubscriberObj?.value ? true : false,
    }
  );

  // get adverseLetter Data
  const { data: getAdverseLetter, isLoading: isLoadingAdverseLetter } = useGetOne(
    "adverse-letter",
    {
      id: switchSubscriberObj?.value,
    },
    {
      refetchOnWindowFocus: false,
      enabled: switchSubscriberObj?.value ? true : false,
    }
  );

  // get subscriberScreeningCompany Data
  const { data: getSubscriberScreeningCompany, isLoading: isLoadingSubscriberScreeningCompany } = useGetOne(
    "subscriber-screening-company",
    {
      id: switchSubscriberObj?.value,
    },
    {
      refetchOnWindowFocus: false,
      enabled: switchSubscriberObj?.value ? true : false,
    }
  );

  // get getThirdPartyApps Data
  const { data: getThirdPartyApps, isLoading: isLoadingThirdPartyApps } = useGetOne(
    "get-third-party-apps",
    {
      id: switchSubscriberObj?.value,
    },
    {
      refetchOnWindowFocus: false,
      enabled: switchSubscriberObj?.value ? true : false,
    }
  );

  // get getPropertyDetails Data
  const { data: getPropertyDetails, isLoading: loadingPropertyDetails } =
    useGetOne(
      "get-property-details",
      {
        id: {
          propertyID: id,
          subscriberID: switchSubscriberObj?.value,
          type: state?.type,
        },
      },
      {
        refetchOnWindowFocus: false,
        enabled: switchSubscriberObj?.value && id ? true : false,
      }
    );

  const {
    data: getIntegrationSettingRuleList,
    isLoading: getIntegrationSettingRuleListLoading,
  } = useGetList(
    "integration-setting-rule",
    {
      filter: {
        subscriberID: switchSubscriberObj?.value,
        thirdPartyAppId:
          getPropertyDetails?.data[0]?.ThirdPartyApp?.value ||
          getPropertyDetails?.data[0]?.ThirdPartyApp,
      },
    },
    {
      enabled:
        switchSubscriberObj?.value && getPropertyDetails?.data[0]?.ThirdPartyApp
          ? true
          : false,
      refetchOnWindowFocus: false,
    }
  );

  // get get-subscriber-configurations Data
  const { data: getSubscriberConfigurations, isLoading: isLoadingSubscriberConfigurations } = useGetOne(
    "get-subscriber-configurations",
    {
      id: switchSubscriberObj?.value,
    },
    {
      refetchOnWindowFocus: false,
      enabled: switchSubscriberObj?.value ? true : false,
    }
  );

  // form validation schema
  const validationSchema = yup.lazy((value) => {
    
    return yup.object().shape({
      PropertyName: formValidation.name,
      email: yup
        .array()
        .of(yup.string())
        .test("emails", "Required!", (value: any) => {
          return value?.length > 0 ? true : false;
        }),
      status: formValidation.StringRequiredValidation,
      StreetAddress: formValidation.streetAddress,
      ZipCode: formValidation.zipCode,
      City: formValidation.city,
      state: formValidation.validationForDropDown,
      Telephone: formValidation.mobileNo,
      Fax: formValidation.mobileNoNotRequired,
      application_profile: formValidation.validationForDropDown,
      screeningFee: formValidation.feeValidation,
      subscriberFee: formValidation.feeValidation,
      processingFee: formValidation.processingFee,
      applicationFee: formValidation.feeValidation,
      default_adverse_action_letter_type: formValidation.validationForDropDown,
      screening_company: getSubscriberConfigurations?.records?.Screening === 1 && formValidation.validationForDropDown,
      hierarchy_level: yup
        .object()
        .shape({
          value: yup.string().nullable(),
          label: yup.string().nullable(),
        })
        .nullable(),
      NumberofUnits:
        state?.type !== "Office"
          ? formValidation.NumberValidation
          : yup.string().nullable().notRequired(),
      enable_stripe_account:
        getPropertyDetails?.data[0]?.SubscriberStipeEnabled &&
        yup.string().nullable().required("Required!").typeError("Required!"),
      useStripeAccountOf:
        getPropertyDetails?.data[0]?.SubscriberStipeEnabled &&
        yup.string().nullable().required("Required!"),
      select_integration_rule_type:
        value?.third_party_integration &&
        value?.third_party_integration !== null &&
        value?.third_party_integration?.value !== null &&
        yup
          .object()
          .shape({
            value: yup.string().required("Required!").nullable(),
            label: yup.string().nullable(),
          })
          .nullable(),
      // formValidation.validationForDropDown,
      property_ID:
        value?.third_party_integration &&
        value?.third_party_integration !== null &&
        value?.third_party_integration?.value !== null &&
        yup.string().nullable().required("Required!"),

      // property_ID:
      //   value?.third_party_integration &&
      //   value?.third_party_integration !== null &&
      //   value?.third_party_integration?.value !== null &&
      //   yup.string().when('third_party_integration.value', {
      //     is: (value) => value == 1,
      //     then: yup.string().matches(/^[0-9]+$/, 'ID must be numeric').required('Property ID is required'),

      //     otherwise: yup.string().matches(/^[0-9a-zA-Z]+$/, 'ID must be alphanumeric').required('Property ID is required'),
      //   }),

      docUpload:
        getSubscriberConfigurations?.records?.DocUpload === 1 &&
        value?.third_party_integration &&
        value?.third_party_integration !== null &&
        value?.third_party_integration?.value !== null &&
        yup.string().required("Required!").typeError("Required!"),
      guestCard:
        getSubscriberConfigurations?.records?.GuestCard === 1 &&
        value?.third_party_integration &&
        value?.third_party_integration !== null &&
        value?.third_party_integration?.value !== null &&
        yup.string().required("Required!").typeError("Required!"),
      file:
        value?.file &&
        value?.file?.type != undefined &&
        yup
          .mixed()
          .nullable()
          .test(
            "file_size",
            "File size exceeds 4Mb",
            (value) => !value || (value && value?.size <= file_size)
          )
          .test(
            "file_type",
            "Supported file format is image files only.",
            (value) => !value || (value && file_type.includes(value?.type))
          ),
    })
  }

  );

  // Initial form values
  const defaultFormValues = {
    PropertyName: "",
    email: [],
    status: 1,
    StreetAddress: "",
    ZipCode: "",
    City: "",
    state: "",
    Telephone: "",
    Fax: "",
    application_profile: { value: undefined, label: "" },
    screeningFee: "",
    subscriberFee: "",
    applicationFee: "",
    processingFee: "",
    default_adverse_action_letter_type: "",
    screening_company: "",
    hierarchy_level: "",
    lease_template: "",
    // NumberofUnits: ,
    // payee_name: "",
    // payee_address: "",
    enable_stripe_account: "",
    useStripeAccountOf: "",
    // stripe_account_status: "",
    // bank_name: "",
    // account_holder_name: "",
    // account_number: "",
    // routing_number: "",
    // tax_id: "",
    third_party_integration: { value: null, label: "" },
    select_integration_rule_type: "",
    property_ID: "",
    docUpload: "",
    guestCard: "",
    addressSearch: "",
    file: null,
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    setLoadingData(true);
    if (getPropertyDetails?.data.length > 0) {
      getPropertyDetails?.data?.map((propertyDetails, index) => {
        getStates?.data?.filter((state) => {
          if (state.StateShortCode == propertyDetails?.State) {
            let body = {
              value: state?.id,
              label: state?.StateShortCode + " - " + state?.StateName,
            };

            propertyDetails.State = body;
          }
        });
        getApplicationProfile?.data?.filter((appProfile) => {
          if (appProfile.ID == propertyDetails?.ApplicationProfileID) {
            let body = {
              value: appProfile?.ID,
              label: appProfile?.ProfileName,
            };

            propertyDetails.ApplicationProfileID = body;
          }
        });
        getHierarchyLevel?.filter((hierarchy) => {
          if (hierarchy.id === propertyDetails?.HierarchyID) {
            let body = {
              value: hierarchy?.id,
              label: hierarchy?.HierarchyName,
            };

            propertyDetails.HierarchyID = body;
          }
        });
        getThirdPartyApps?.data?.filter((thirdParty) => {
          if (thirdParty.appID == propertyDetails?.ThirdPartyApp) {
            let body = {
              value: thirdParty?.appID,
              label: thirdParty?.appName,
            };

            propertyDetails.ThirdPartyApp = body;
          }
        });
        getAdverseLetter?.data?.filter((adverseLetter) => {
          if (adverseLetter.ID == propertyDetails?.AdverseLetterID) {
            let body = {
              value: adverseLetter?.ID,
              label: adverseLetter?.LetterName,
            };

            propertyDetails.AdverseLetterID = body;
          }
        });
        getSubscriberScreeningCompany?.data?.filter((screeningCompany) => {
          if (screeningCompany.ID == propertyDetails?.ScreeningCompanyID) {
            let body = {
              value: screeningCompany?.ID,
              label: screeningCompany?.Name,
            };

            propertyDetails.ScreeningCompanyID = body;
          }
        });
        getLeaseTemplate?.data?.filter((leaseTemplate) => {
          if (leaseTemplate.ID == propertyDetails?.LeaseTemplateID) {
            let body = {
              value: leaseTemplate?.ID,
              label: leaseTemplate?.LeaseName,
            };

            propertyDetails.LeaseTemplateID = body;
          }
        });
        getIntegrationSettingRuleList?.filter((item) => {
          if (item?.id == propertyDetails?.IntegrationRuleID) {
            let body = {
              value: item?.id,
              label: item?.ruleName,
            };
            propertyDetails.IntegrationRuleID = body;
          }
        });
      });
    }

    let data = getPropertyDetails?.data[0];
    const getFormData = {
      ...data,
      PropertyName:
        state?.type === "Office" ? data?.OfficeName : data?.PropertyName,
      email: data?.Email?.split(","),
      status: data?.Active ? 1 : 0,
      ZipCode: (data?.ZipCode?.length > 5 && data?.ZipCode.slice(5, 6) !== "-") ? (data?.ZipCode.slice(0, 5) + "-" + data?.ZipCode.slice(5)) : data?.ZipCode,
      state: data?.State,
      application_profile: data?.ApplicationProfileID ? data?.ApplicationProfileID : (getApplicationProfile?.data?.filter((item) => item?.ProfileName === "Default Application Profile")[0]?.ID),
      screeningFee: data?.ScreeningFee,
      Telephone: (data?.Telephone && !data?.Telephone?.match(/^\+/)) ? ("+1 " + data?.Telephone) : data?.Telephone,
      Fax: (data?.Fax && !data?.Fax?.match(/^\+/)) ? ("+1 " + data?.Fax) : data?.Fax,
      subscriberFee: data?.SubscriberFee,
      // applicationFee:methods?.watch('application_profile')?.value!==undefined? (getApplicationProfile?.data?.filter(item=>item?.ID === methods?.watch('application_profile')?.value)[0]?.ApplicationFee) :data?.ApplicationFee,
      applicationFee: data?.ApplicationFee,
      processingFee: data?.ProcessingFee,
      default_adverse_action_letter_type: data?.AdverseLetterID,
      screening_company: data?.ScreeningCompanyID,
      hierarchy_level: data?.HierarchyID,
      lease_template: data?.LeaseTemplateID,
      NumberofUnits: data?.NumberofUnits === 0 ? "0" : data?.NumberofUnits,
      // payee_name: data?.PayeeName,
      // payee_address: data?.PayeeAddress,
      enable_stripe_account: data?.StripeAccountStatus ? "Yes" : "No",
      useStripeAccountOf: data?.StripeAccountEnabled
        ? (state?.type === "Office" ? "Office" : "Property")
        : "SUBSCRIBER",
      // stripe_account_status: data?.StripeAccountStatus ? "Active" : "Inactive",
      // bank_name: data?.StripeExternalBankAccountId
      //   ? data?.StripeExternalBankAccountId
      //   : "",
      // account_holder_name: data?.StripeAccountHolderName
      //   ? data?.StripeAccountHolderName
      //   : "",
      // account_number: data?.AccountNumber
      //   ? "**********" + data?.AccountNumber?.toString()?.slice(-4)
      //   : "",
      // routing_number: data?.StripeRoutingNumber
      //   ? data?.StripeRoutingNumber
      //   : "",
      // tax_id: data?.TaxID ? data?.TaxID : "",
      third_party_integration: data?.ThirdPartyApp,
      select_integration_rule_type: data?.IntegrationRuleID,
      property_ID: data?.ThirdPartyPropertyID ? data?.ThirdPartyPropertyID : "",
      docUpload: data?.EnableDocUpload ? 1 : 0,
      guestCard: data?.EnableGuestCard ? 1 : 0,
      file: data?.LogoURL,
    };
    methods.reset(getFormData);

    setTimeout(() => {
      setLoadingData(false);
    }, 1000);


  }, [
    getPropertyDetails?.data,
    getPropertyDetails?.data[0],
    getStates?.data,
    getIntegrationSettingRuleList,
    getApplicationProfile?.data,
  ]);

  const onApplicationProfileChange = () => {
    methods?.setValue("applicationFee", methods?.watch('application_profile')?.value !== undefined ? (getApplicationProfile?.data?.filter(item => item?.ID === methods?.watch('application_profile')?.value)[0]?.ApplicationFee) : getPropertyDetails?.data[0]?.ApplicationFee)
  };

  useEffect(() => {
    if (methods?.watch("ZipCode")?.length > 5 && methods?.watch("ZipCode")?.slice(5, 6) !== "-")
      methods?.setValue("ZipCode", methods?.watch("ZipCode")?.slice(0, 5) + "-" + methods?.watch("ZipCode")?.slice(5));
  }, [methods?.watch("ZipCode")])

  const screeningFeeWatch = methods?.watch(`screeningFee`);
  const subscriberFeeWatch = methods?.watch(`subscriberFee`);
  const applicationFeeWatch = methods?.watch(`applicationFee`);
  useEffect(() => {
    methods?.setValue("processingFee", `${((+applicationFeeWatch) - (+subscriberFeeWatch) - (+screeningFeeWatch))}`)
  }, [screeningFeeWatch, subscriberFeeWatch, applicationFeeWatch]);


  const [create, { isLoading, error }] = useCreate();

  // form submit handler
  const onSubmit = (value) => {
   
    const formData: any = new FormData();
    formData.append("ID", id);
    formData.append("subscriberID", switchSubscriberObj?.value);
    formData.append(
      state?.type === "Office" ? "officeName" : "propertyName",
      value?.PropertyName
    );
    formData.append("email", value?.email?.join(","));
    formData.append("active", value?.status);
    formData.append("streetAddress", value?.StreetAddress);
    formData.append("zipCode", value?.ZipCode.replace("-", ""));
    formData.append("city", value?.City);
    formData.append("state", value?.state ? JSON.stringify(value?.state) : "");
    formData.append("telephone", value?.Telephone);
    formData.append("fax", value?.Fax);
    formData.append(
      "applicationProfileID",
      value?.application_profile
        ? JSON.stringify(value?.application_profile)
        : ""
    );
    formData.append("processingFee", value?.processingFee);
    formData.append("screeningFee", value?.screeningFee);
    formData.append("subscriberFee", value?.subscriberFee);
    formData.append("applicationFee", value?.applicationFee);
    formData.append(
      "adverseLetterID",
      value?.default_adverse_action_letter_type
        ? JSON.stringify(value?.default_adverse_action_letter_type)
        : ""
    );
    formData.append(
      "screeningCompanyID",
      value?.screening_company ? JSON.stringify(value?.screening_company) : ""
    );
    formData.append(
      "hierarchyID",
      value?.hierarchy_level ? JSON.stringify(value?.hierarchy_level) : ""
    );
    formData.append(
      "leaseTemplateID",
      value?.lease_template ? JSON.stringify(value?.lease_template) : ""
    );
    if (state?.type !== "Office") {
      formData.append("units", value?.NumberofUnits);
    }
    // formData.append("payeeName", value?.payee_name);
    // formData.append("payeeAddress", value?.payee_address);
    formData.append(
      "stripeAccountStatus",
      value?.enable_stripe_account == "Yes" ? 1 : 0
    );
    // formData.append(
    //   "useStripeAccountOf",
    //   value?.useStripeAccountOf && value?.useStripeAccountOf?.toUpperCase()
    // );
    formData.append(
      "stripeAccountEnabled",
      value?.subscriberFee > 0 && (value?.useStripeAccountOf === "Office" || value?.useStripeAccountOf === "Property") ? 1 : 0
    );
    // formData.append("stripeExternalBankAccountId", value?.bank_name);
    // formData.append("stripeAccountHolderName", value?.account_holder_name);
    // formData.append("accountNumber", value?.account_number);
    // formData.append("StripeRoutingNumber", value?.routing_number);
    // formData.append("taxID", value?.tax_id);
    formData.append(
      "thirdPartyApp",
      value?.third_party_integration
        ? value?.third_party_integration?.value !== null
          ? JSON.stringify(value?.third_party_integration)
          : ""
        : ""
    );
    formData.append(
      "integrationRuleID",
      value?.third_party_integration &&
        value?.third_party_integration?.value !== null
        ? value?.select_integration_rule_type
          ? JSON.stringify(value?.select_integration_rule_type)
          : ""
        : ""
    );
    formData.append(
      "thirdPartyPropertyID",
      value?.third_party_integration &&
        value?.third_party_integration?.value !== null
        ? value?.property_ID
          ? value?.property_ID
          : ""
        : ""
    );
    formData.append(
      "enableGuestCard",
      getSubscriberConfigurations?.records?.GuestCard === 1 &&
        value?.third_party_integration &&
        value?.third_party_integration?.value !== null
        ? value?.guestCard
          ? value?.guestCard
          : 0
        : 0
    );
    formData.append(
      "enableDocUpload",
      getSubscriberConfigurations?.records?.DocUpload === 1 &&
        value?.third_party_integration &&
        value?.third_party_integration?.value !== null
        ? value?.docUpload
          ? value?.docUpload
          : 0
        : 0
    );
    formData.append(
      "file",
      value?.file && value?.file?.type != undefined ? value?.file : ""
    );
    formData.append(
      "logo",
      value?.file ? getPropertyDetails?.data[0]?.Logo : ""
    );
    formData.append(
      "LogoURL",
      value?.file ? getPropertyDetails?.data[0]?.LogoURL : ""
    );
    create(
      state?.type === "Office" ? "update-office" : "update-property",
      { data: formData },
      {
        onSuccess: (data) => {
          notify(
            `${state?.type === "Office" ? "Office" : "Property"
            } updated successfully.`,
            {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          refresh();
          methods?.reset();
          state?.type === "Office"
            ? navigate("/ats/settings/officeSetup")
            : navigate("/ats/settings/propertySetup");
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
const {permissions} = usePermissions();
  useEffect(() => {
    document.title =
      state?.type === "Office"
        ? "Office Setup - Office Details"
        : "Property Setup - Property Details";
  }, []);

  useEffect(() => {
    if (getThirdPartyApps?.data?.length > 0) {
      const additionalObject = {
        appID: null,
        appName: "None",
        subscriberAppID: null,
      };
      const modifiedData = [...getThirdPartyApps?.data, additionalObject];
      setGetThirdPartyAppsModifiedData(modifiedData);
    }
  }, [getThirdPartyApps?.data]);

  // Function to determine the flag value
  const hasAnyStatusOne = (objects) => {
    // Use the some method for concise and efficient checking
    return objects ? objects?.some((object) => object?.status === 1) : false;
  };

  useEffect(() => {
    if (getSubscriberConfigurations?.records) {
      let thirdPartyIntegration;
      if (
        typeof getSubscriberConfigurations?.records?.ThirdPartyIntegration !==
        "object"
      ) {
        thirdPartyIntegration = JSON?.parse(
          getSubscriberConfigurations?.records?.ThirdPartyIntegration
        );
      } else {
        thirdPartyIntegration =
          getSubscriberConfigurations?.records?.ThirdPartyIntegration;
      }
      setIsThirdPartyIntegration(hasAnyStatusOne(thirdPartyIntegration));
    }
  }, [getSubscriberConfigurations?.records]);

  if (loadingPropertyDetails || getPropertyDetailsLoading
    || isLoadingStates || isLoadingHierarchyLevel
    || isLoadingLeaseTemplate || getIntegrationSettingRuleListLoading
    || isLoadingAdverseLetter || isLoadingSubscriberScreeningCompany
    || isLoadingThirdPartyApps || isLoadingSubscriberConfigurations || isLoadingData) return <Loading />
  return (
    <Fragment>
      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex justify-content-between">
            <Breadcrumbs aria-label="breadcrumb">
              <Typography sx={{ fontWeight: "bold" }}>
                <button
                  title="Go Back"
                  style={{ color: "#2cc0e4" }}
                  className="btn btn-link"
                  onClick={() =>
                    state?.type === "Office"
                      ? navigate("/ats/settings/officeSetup")
                      : navigate("/ats/settings/propertySetup")
                  }
                >
                  <ArrowBack />
                </button>
                {/* <span>Email Settings &gt; Template Details</span> */}
                <span>
                  {state?.type === "Office" ? "Office" : "Property"} Details
                </span>
              </Typography>
            </Breadcrumbs>
            {(getPropertyDetails?.data[0]?.SubscriberStipeEnabled && parseInt(methods?.watch("subscriberFee")) > 0) ? (
              <Button
                style={{ marginLeft: "350px" }}
                sx={{ textTransform: "capitalize", fontWeight: "bold" }}
                variant="outlined"
                size="medium"
                startIcon={
                  <svg
                    width="12"
                    height="15"
                    viewBox="0 0 12 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 0.832031V2.16536H1.33333L1.33267 8.83203H10.666L10.6667 2.16536H8V0.832031H11.3333C11.5101 0.832031 11.6797 0.902269 11.8047 1.02729C11.9298 1.15232 12 1.32189 12 1.4987V13.4987C12 13.6755 11.9298 13.8451 11.8047 13.9701C11.6797 14.0951 11.5101 14.1654 11.3333 14.1654H0.666667C0.489856 14.1654 0.320286 14.0951 0.195262 13.9701C0.0702379 13.8451 0 13.6755 0 13.4987V1.4987C0 1.32189 0.0702379 1.15232 0.195262 1.02729C0.320286 0.902269 0.489856 0.832031 0.666667 0.832031H4ZM10.666 10.1654H1.33267L1.33333 12.832H10.6667L10.666 10.1654ZM9.33333 10.832V12.1654H8V10.832H9.33333ZM6.66667 0.832031V4.16536H8.66667L6 6.83203L3.33333 4.16536H5.33333V0.832031H6.66667Z"
                      fill="#2DC3E8"
                    />
                  </svg>
                }
                onClick={() =>
                  navigate(
                    `/ats/settings/${state?.type === "Office"
                      ? "officeSetup/officeDetails"
                      : "propertySetup/propertyDetails"
                    }/stripeAccount/${id}`,
                    {
                      state: {
                        isAdding: getPropertyDetails?.data[0]?.StripeAccountId
                          ? false
                          : true,
                        email: getPropertyDetails?.data[0]?.Email?.split(","),
                        type: state?.type,
                      },
                    }
                  )
                }
              >
                {getPropertyDetails?.data[0]?.StripeAccountId
                  ? "Update Stripe Account"
                  : "Add Stripe Account"}
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>

        <FormProvider {...methods}>
          <form
            onSubmit={(event) => {
              event.stopPropagation();
              event.preventDefault();
              methods.handleSubmit(onSubmit)();
            }}
            className="mr-t30"
          >
            <fieldset disabled={!checkModuleAccess(permissions, "Other Permissions", "Edit Properties/Offices")}>
          
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label={
                    state?.type === "Office" ? "Office Name" : "Property Name"
                  }
                  source={`PropertyName`}
                  placeholder={`Enter ${state?.type === "Office" ? "Office" : "Property"
                    } Name`}
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <MultiEmailInput
                  label="Email"
                  source={`email`}
                  placeholder="Enter Email"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <ToggleButtons
                  toggleButton={statusToggleButton}
                  label="Status"
                  labelClass={"lable-title"}
                  source={`status`}
                />
              </div>
              <div className="col-sm-12 mr-b20">
                <GoogleAutoComplete
                  notRequired={true}
                  groupFieldName={{
                    street: `StreetAddress`,
                    city: `City`,
                    state: `state`,
                    zip: `ZipCode`,
                    state_data: getStates?.data?.map((item) => ({
                      value: item?.id,
                      label: item?.StateShortCode + " - " + item?.StateName,
                    })),
                  }}
                  register={methods?.register(`addressSearch`)}
                  label={"Search  Address"}
                  labelClass={"lable-title"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Street Address"
                  source={`StreetAddress`}
                  placeholder="Enter Street Address"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="City"
                  source={`City`}
                  placeholder="Enter City"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`State`}
                  source={`state`}
                  options={getStates?.data?.map((item) => ({
                    value: item?.id,
                    label: item.StateShortCode + " - " + item.StateName,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  disable={!checkModuleAccess(permissions, "Other Permissions", "Edit Properties/Offices")}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Zip Code"
                  source={`ZipCode`}
                  placeholder="Enter Zip Code"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <TextInputPhone
                  source={`Telephone`}
                  label="Telephone"
                  isCountryCode={`country_code`}
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  widthClass={"w-100"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <TextInputPhone
                  source={`Fax`}
                  label="Fax"
                  isCountryCode={`country_code`}
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  widthClass={"w-100"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  required={false}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`Application Profile`}
                  source={`application_profile`}
                  options={getApplicationProfile?.data?.map((item) => ({
                    value: item?.ID,
                    label: item?.ProfileName,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  onChangeCallback={() => {
                    methods?.trigger(`application_profile`);
                    onApplicationProfileChange();
                  }}
                  disable={!checkModuleAccess(permissions, "Other Permissions", "Edit Properties/Offices")}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Application Fee"
                  source={`applicationFee`}
                  required={true}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    methods?.trigger(`applicationFee`);
                  }}
                  removeDefaultZeroValue={true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Screening Fee"
                  source={`screeningFee`}
                  required={true}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    methods?.trigger(`screeningFee`);
                  }}
                  removeDefaultZeroValue={true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Subscriber Fee"
                  source={`subscriberFee`}
                  required={true}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    methods?.trigger(`subscriberFee`);
                  }}
                  removeDefaultZeroValue={true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Processing Fee"
                  source={`processingFee`}
                  required={false}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    methods?.trigger(`processingFee`);
                  }}
                  disabled={true}
                  allowNegative={true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`Default Adverse Action Letter type`}
                  source={`default_adverse_action_letter_type`}
                  options={getAdverseLetter?.data?.map((item) => ({
                    value: item?.ID,
                    label: item?.LetterName,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  onChangeCallback={() => { methods?.trigger(`default_adverse_action_letter_type`) }}
                  disable={!checkModuleAccess(permissions, "Other Permissions", "Edit Properties/Offices")}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <div className="row">
                  <div className="col-12">
                    <InputSelectField
                      label={`Screening Company`}
                      source={`screening_company`}
                      options={getSubscriberScreeningCompany?.data?.map(
                        (item) => ({
                          value: item?.ID,
                          label: item?.Name,
                        })
                      )}
                      labelAsteriskClass={"color-red"}
                      labelClass={"lable-title"}
                      required={getSubscriberConfigurations?.records?.Screening === 0 ? false : true}
                      disable={getSubscriberConfigurations?.records?.Screening === 0 ? true : false || !checkModuleAccess(permissions, "Other Permissions", "Edit Properties/Offices")}
                      requiredClass={{
                        fontSize: "70%",
                      }}
                      onChangeCallback={() => { methods?.trigger(`screening_company`) }}
                     
                    />
                  </div>
                  {/* <div className="col-2 d-flex justify-content-center align-items-end">
                    <IconButton
                      aria-label="setting"
                      color="primary"
                      sx={{
                        border: "1px solid #2DC3E8",
                        borderRadius: "2px",
                        marginBottom: "4px",
                      }}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </div> */}
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`Hierarchy Level`}
                  source={`hierarchy_level`}
                  options={getHierarchyLevel?.flatMap((item) => (
                    item?.Active ?
                      {
                        value: item?.id,
                        label: item?.HierarchyName,
                      }
                      : []
                  ))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  required={false}
                  disable={!checkModuleAccess(permissions, "Other Permissions", "Edit Properties/Offices")}
                />
              </div>
              {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`Lease Template`}
                  source={`lease_template`}
                  options={getLeaseTemplate?.data?.map((item) => ({
                    value: item?.ID,
                    label: item?.LeaseName,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  required={false}
                />
              </div> */}
              {state?.type !== "Office" && (
                <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                  <InputField2
                    label="No of Units"
                    source={`NumberofUnits`}
                    type="number"
                    placeholder="Enter No of Units"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                    notRequired={state?.type !== "Office" ? false : true}
                  />
                </div>
              )}
              {/* Payee name and Payee Address- hide the options​ */}
              {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Payee Name"
                  source={`payee_name`}
                  placeholder="Enter Payee Name"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  notRequired={true}
                />
              </div>
              <div className="col-sm-12 col-md-8 col-lg-8 mr-b20">
                <InputField2
                  label="Payee Address"
                  source={`payee_address`}
                  placeholder="Enter Payee Address"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  notRequired={true}
                />
              </div> */}
              {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <div className="d-flex">
                  <ToggleButtons
                    toggleButton={docUploadToggleButton}
                    label="DocUpload"
                    labelClass={"lable-title"}
                    source={`docUpload`}
                  />
                  <div className="mx-4">
                    <ToggleButtons
                      toggleButton={guestCardToggleButton}
                      label="GuestCard"
                      labelClass={"lable-title"}
                      source={`guestCard`}
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <ToggleButtons
                  toggleButton={docUploadToggleButton}
                  label="DocUpload"
                  labelClass={"lable-title"}
                  source={`docUpload`}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <ToggleButtons
                  toggleButton={guestCardToggleButton}
                  label="GuestCard"
                  labelClass={"lable-title"}
                  source={`guestCard`}
                />
              </div> */}
            </div>
            {(getPropertyDetails?.data[0]?.SubscriberStipeEnabled && parseInt(methods?.watch("subscriberFee")) > 0) ? (
              <div className="row">
                <div className="col-sm-12 mr-b20">
                  <div
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.16)",
                      borderRadius: "4px",
                      padding: "10px 16px 16px 16px",
                    }}
                  >
                    <div className="mb-3">
                      <label className="lable-title">Stripe Account</label>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                        <div className="resp_buttons">
                          <CustomRadioButton
                            label="Enable Stripe Account?"
                            register={methods?.register(
                              "enable_stripe_account"
                            )}
                            onClick={() => { return }}
                            handleOnChange={() => { return }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                        <div className="resp_buttons">
                          <StripeAccountRadioButton
                            label="Use Stripe Account Of"
                            register={methods?.register("useStripeAccountOf")}
                            // propertyDisable={
                            //   getPropertyDetails?.data[0]?.StripeAccountId
                            //     ? false
                            //     : true
                            // }
                            secondButtonValue={
                              state?.type === "Office" ? "Office" : "Property"
                            }
                            onClick={() => { return }}
                            handleOnChange={(e) => {
                              if (
                                !getPropertyDetails?.data[0]?.StripeAccountId &&
                                (e.target?.value === "Office" ||
                                  e.target?.value === "Property")
                              ) {
                                setOpen(true);
                                methods?.setValue(
                                  "useStripeAccountOf",
                                  "SUBSCRIBER"
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                        <InputField2
                          label="Stripe Account Status"
                          source={`stripe_account_status`}
                          placeholder="Enter Stripe Account Status"
                          labelClass={"lable-title"}
                          additionalInputClass={"cus-control"}
                          disable={true}
                          notRequired={true}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                        <InputField2
                          label="Bank Name"
                          source={`bank_name`}
                          placeholder="Enter Bank Name"
                          labelClass={"lable-title"}
                          additionalInputClass={"cus-control"}
                          disable={true}
                          notRequired={true}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                        <InputField2
                          label="Account Holder Name"
                          source={`account_holder_name`}
                          placeholder="Enter Account Holder Name"
                          labelClass={"lable-title"}
                          additionalInputClass={"cus-control"}
                          disable={true}
                          notRequired={true}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                        <InputField2
                          label="Account Number"
                          source={`account_number`}
                          placeholder="Enter Account Number"
                          labelClass={"lable-title"}
                          additionalInputClass={"cus-control"}
                          disable={true}
                          notRequired={true}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                        <InputField2
                          label="Routing Number"
                          source={`routing_number`}
                          placeholder="Enter Routing Number"
                          labelClass={"lable-title"}
                          additionalInputClass={"cus-control"}
                          disable={true}
                          notRequired={true}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                        <InputField2
                          label="Tax ID (EIN)"
                          source={`tax_id`}
                          placeholder="Enter Tax ID (EIN)"
                          labelClass={"lable-title"}
                          additionalInputClass={"cus-control"}
                          disable={true}
                          notRequired={true}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {(getSubscriberConfigurations?.records?.DocUpload === 1 ||
              getSubscriberConfigurations?.records?.GuestCard === 1) &&
              isThirdPartyIntegration &&
              (
                <div className="row">
                  <div className="col-sm-12 mr-b30">
                    <div
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.16)",
                        borderRadius: "4px",
                        padding: "10px 16px 16px 16px",
                      }}
                    >
                      <div className="mb-3">
                        <label className="lable-title">
                          PMS Integration Details
                        </label>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                          <InputSelectField
                            label={`Select PMS`}
                            source={`third_party_integration`}
                            options={getThirdPartyAppsModifiedData?.map(
                              (item) => ({
                                value: item?.appID,
                                label: item?.appName,
                              })
                            )}
                            labelAsteriskClass={"color-red"}
                            labelClass={"lable-title"}
                            requiredClass={{
                              fontSize: "70%",
                            }}
                            onChangeCallback={() => {
                              methods?.trigger(`select_integration_rule_type`);
                              methods?.trigger(`property_ID`);
                            }}
                            required={false}
                            disable={!checkModuleAccess(permissions, "Other Permissions", "Edit Properties/Offices")}
                          />
                        </div>
                        <IntegrationRuleType
                          methods={methods}
                          switchSubscriberObj={switchSubscriberObj}
                        />
                        {methods?.watch(`third_party_integration`) &&
                          methods?.watch(`third_party_integration`)?.value !== null ? (
                          <>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                              <InputField2
                                label="Property ID"
                                source={`property_ID`}
                                placeholder="Enter Property ID"
                                labelClass={"lable-title"}
                                additionalInputClass={"cus-control"}
                                notRequired={
                                  methods?.watch(`third_party_integration`) &&
                                    methods?.watch(`third_party_integration`)?.value !==
                                    null
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                              <div className="d-flex">
                                {getSubscriberConfigurations?.records?.DocUpload ===
                                  1 && (
                                    <ToggleButtons
                                      toggleButton={docUploadToggleButton}
                                      label="DocUpload"
                                      labelClass={"lable-title"}
                                      source={`docUpload`}
                                    />
                                  )}
                                {getSubscriberConfigurations?.records?.GuestCard ===
                                  1 && (
                                    <div
                                      className={
                                        getSubscriberConfigurations?.records
                                          ?.DocUpload === 1
                                          ? "mx-4"
                                          : ""
                                      }
                                    >
                                      <ToggleButtons
                                        toggleButton={guestCardToggleButton}
                                        label="GuestCard"
                                        labelClass={"lable-title"}
                                        source={`guestCard`}
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                          </>) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <div className="row mr-b50">
              <div className="col-12 mr-b20">
                <div
                  className="p-3"
                  style={{ background: "#f5fdff", borderRadius: "8px" }}
                >
                  <div className="row">
                    <div className="col-sm-12 col-md-2 col-lg-2 d-flex align-items-center">
                      <label className="lable-title">
                        {" "}
                        {state?.type === "Office"
                          ? "Office Logo"
                          : "Property Logo"}
                      </label>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-4">
                      <div className="d-flex align-items-center">
                        {methods?.watch(`file`) !== null &&
                          !methods?.formState?.errors?.file && (
                            <FilePreview2
                              img={methods?.watch(`file`)}
                              isPdf={false}
                              isDeletabel={false}
                              isfileName={false}
                            />
                          )}
                        {/* <div className="mx-3">
                          <InputFileUpload
                            source={`file`}
                            acceptedFiles={"image/*"}
                            buttonText={ methods?.watch('file')?"Change":"Upload"}
                          />
                        </div> */}
                        {(methods?.watch(`file`) !== null &&
                          !methods?.formState?.errors?.file) ?
                          <div className="mx-3">
                            <Button
                              color='error'
                              startIcon={<DeleteIcon />}
                              onClick={() => {
                                methods?.setValue(`file`, null);
                              }}
                            >Delete</Button>
                          </div> :
                          <div className="mx-3">
                            <InputFileUpload
                              source={`file`}
                              acceptedFiles={"image/*"}
                              buttonText={`Upload`}
                            />
                          </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mr-b50">
              {getPropertyDetails?.data[0]?.SubscriberAts &&
                getPropertyDetails?.data[0]?.URL ? (
                <div className="col-12 mr-b10">
                  <CopyUrls
                    heading={"Application URL"}
                    url={REACT_APPLICANT + '/' + getPropertyDetails?.data[0]?.URL}
                  />
                </div>
              ) : (
                <></>
              )}
              {getPropertyDetails?.data[0]?.SubscriberDocUpload &&
                getPropertyDetails?.data[0]?.DocUploadURL ? (
                <div className="col-12 mr-b10">
                  <CopyUrls
                    heading={"Doc Upload URL"}
                    url={`${window.location.protocol}//${window.location.host}/atss/cp/` + getPropertyDetails?.data[0]?.DocUploadURL}
                  />
                </div>
              ) : (
                <></>
              )}
              {getPropertyDetails?.data[0]?.SubscriberGuestCard &&
                getPropertyDetails?.data[0]?.GuestCardURL ? (
                <div className="col-12 mr-b10">
                  <CopyUrls
                    heading={"Guest Card URL"}
                    url={`${window.location.protocol}//${window.location.host}/atss/cp/` + getPropertyDetails?.data[0]?.GuestCardURL}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {state?.type === "Office" ? "" : <FloorPlanAndUnits />}
            <Box
             display={"flex"}
             justifyContent={"end"}
             alignItems={"center"}
             className="mt-4"
             minHeight="10vh"
            >
              <Box>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    refresh();
                    methods?.reset();
                    state?.type === "Office"
                      ? navigate("/ats/settings/officeSetup")
                      : navigate("/ats/settings/propertySetup");
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={<img src={save_icon} alt="save icon"
                    onError={(event) => {
                      const imgElement = event.target as HTMLImageElement; // Type cast to HTMLImageElement
                      imgElement.style.display = 'none';
                      imgElement.style.visibility = 'hidden';
                    }} />}
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  Update
                </Button>
              </Box>
            </Box>
            </fieldset>
          </form>
          {/* Modal for showing alert message */}
          <AlertForStripeModal
            open={open}
            setOpen={setOpen}
            type={state?.type === "Office" ? "office" : "property"}
          />
        </FormProvider>
      </div>
    </Fragment>
  );
};

export default PropertyDetails;
