import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {
  Button,
  useCreate,
  useGetList,
  useGetOne,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
  useStore,
} from "react-admin";
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputSelectField from "../common/InputComponents/InputSelectField";
import { Navigate, useNavigate } from "react-router-dom";
import { BiArrowBack, BiRedo, BiSwim } from "react-icons/bi";
import { TiArrowBack, TiArrowSyncOutline } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBack } from "@mui/icons-material";
import { JSONTree } from "react-json-tree";


type SwitchSubscriberPropType = {
  role?: string;
};

const SwitchSubscriber = ({ role }: SwitchSubscriberPropType) => {
  const dispatch = useDispatch();
  // const switchSubscriber= useSelector((state: RootState) => state.users.switchSubscriber);
  // const [switchSubscriber, setSwitchSubscriber] = useStore('switchSubscriber', "false");
  // const {permissions:switchSubscriber}  =  usePermissions();
  const switchSubscriber =localStorage.getItem("RaStore.switchSubscriber")
  const [switchSubscriberObj, setSwitchSubscriberObj] = useStore('switchSubscriberName', { value: '', label: '', companyType: '' });
  // state for handled a module open and close
  const [openSelect, setOpenSelect] = React.useState(false);
  const handleOpenSelect = () => setOpenSelect(true);
  const handleCloseSelect = () => setOpenSelect(false);
  // navigation declaration
  const navigate = useNavigate();

  // call get data for subscriberlist (getsubscribers)
  const {
    data: subscriberlist,
    isLoading: subscriberlistIsLoading,
    error: subscriberlistError,
  } = useGetList("getsubscribers", {}, { enabled: openSelect });

  // form validation
  const subscriberValidationSchema = Yup.lazy((value) =>
    Yup.object().shape({
      subscriber: Yup.object()
        .shape({
          value: Yup.string().required("Required!").nullable(),
          label: Yup.string().nullable(),
        })
        .nullable()
        .required("Subscriber is required!"),
    })
  );

  // Initial call for useForm or initial state
  const subscriberForm = useForm({
    defaultValues: {
      subscriber: null,
    },
    mode: "onChange",
    resolver: yupResolver(subscriberValidationSchema),
  });

  // on Submit Subscriber Handler
  const onSubmitSubscriber = async(value: any) => {
    // setSwitchSubscriber("true");
    await localStorage.setItem("RaStore.switchSubscriber", JSON.stringify(true))
    setSwitchSubscriberObj({ value: value?.subscriber?.value, label: value?.subscriber?.label, companyType: value?.subscriber?.companyType});
    navigate(`/ats/subscriber/inbox/${value?.subscriber?.value}`, { replace: true });
    handleCloseSelect();
    subscriberForm?.reset();
  };
  const { data } = JSON.parse(localStorage.getItem("auth") || "");
console.log(data.userrole,"roleswitch");
  const onClickHandler = () => {
    if (role !== "Subscriber") {
      handleOpenSelect();
      subscriberForm?.reset();
    } else {
      navigate(`/ats/Admin/inbox/`, {
        replace: true,
      });
    }
  };

  return (
    <>
 
      {(data?.userrole === "Docuverus Super Admin" || data?.userrole === "Docuverus Admin")&&  (
      switchSubscriber == "false" ?
        <button onClick={onClickHandler}
          className="btn btn-link text-white text-decoration-none" style={{ fontSize: "12px", fontWeight: "600" }}><TiArrowSyncOutline /> Switch Subscriber</button>
        // <Button label="Switch"
        //   onClick={onClickHandler}
        //   title={
        //     role !== "Subscriber"
        //       ? "Switch to Subscriber"
        //       : "Switch to Home"
        //   } color="inherit" startIcon={<TiArrowSyncOutline />} variant="text" />
        :
        <button onClick={() => {
          setSwitchSubscriberObj({value:'',label:'', companyType:''});
          // setSwitchSubscriber("false");
          localStorage.setItem("RaStore.switchSubscriber", JSON.stringify(false));
          localStorage.removeItem("RaStore.getAdminAllinboxType.selectedIds");
          localStorage.removeItem("RaStore.firstSelectedApp");
          localStorage.removeItem("RaStore.getsubscriberinbox.selectedIds");
          navigate(`/ats`)
          
        }}
          className="btn btn-link text-white text-decoration-none" style={{ fontSize: "12px", fontWeight: "600" }}><TiArrowSyncOutline /> Switch Back</button>)}
      {/* <button
        type="button"
        className="btn btn-outline g-line-btn border-0"
        onClick={onClickHandler}
        title={
          role !== "Subscriber"
            ? "Switch to Subscriber login"
            : "Switch to Docuverus Admin"
        }
      >
        <svg
          width="25"
          height="23"
          viewBox="0 0 25 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.7519 14.9925C21.4818 13.2581 21.6499 11.3385 21.2323 9.50367C20.8148 7.66881 19.8327 6.01095 18.4242 4.76317C17.0156 3.51538 15.2514 2.74043 13.3796 2.54726C11.5077 2.3541 9.62245 2.75243 7.98875 3.68626L6.87275 1.73213C8.58009 0.756324 10.5135 0.245295 12.48 0.250033C14.4465 0.25477 16.3774 0.775109 18.08 1.75913C23.1313 4.67513 25.0662 10.9189 22.7116 16.1254L24.2214 16.9961L19.5358 19.4869L19.3501 14.1836L20.7519 14.9925ZM4.15812 8.01076C3.42816 9.74517 3.26013 11.6647 3.67766 13.4996C4.09518 15.3345 5.07727 16.9923 6.48583 18.2401C7.8944 19.4879 9.65861 20.2628 11.5304 20.456C13.4023 20.6492 15.2875 20.2508 16.9212 19.317L18.0373 21.2711C16.3299 22.2469 14.3965 22.758 12.43 22.7532C10.4635 22.7485 8.53262 22.2282 6.83 21.2441C1.77875 18.3281 -0.15625 12.0844 2.19837 6.87788L0.6875 6.00826L5.37313 3.51751L5.55875 8.82076L4.157 8.01188L4.15812 8.01076Z"
            fill="#909090"
          />
        </svg> Subscriber
      </button> */}

      <Dialog
        fullWidth
        open={openSelect}
        onClose={handleCloseSelect}
        sx={{ "& .MuiDialog-paperFullWidth ": { height: "390px" } }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Switch Subscriber</DialogTitle>
        <DialogContent>
          {subscriberlistIsLoading ? (
            <>Loading...</>
          ) : (
            <FormProvider {...subscriberForm}>
              <form
                onSubmit={subscriberForm.handleSubmit(onSubmitSubscriber)}
                style={{ marginTop: "5px" }}
              >
                <div className="row">
                  <div className="col-12 mr-b20">
                    <InputSelectField
                      label="Select Subscriber"
                      source={`subscriber`}
                      options={subscriberlist?.map((item) => ({
                        label: item.value,
                        value: item.id,
                        companyType: item.companyType
                      }))}
                      labelAsteriskClass={"color-red"}
                      labelClass={"lable-title"}
                      requiredClass={{
                        fontSize: "70%",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-12 mr-b30">
                  <div className="g-btm-btn d-flex align-items-center justify-content-end mr-t20">
                    <button
                      type="button"
                      className="blue-line-btn mr-r20"
                      onClick={() => {
                        handleCloseSelect();
                        subscriberForm?.reset();
                      }}
                    >
                      <svg
                        className="mr-r10"
                        width="10"
                        height="9"
                        viewBox="0 0 10 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 3.55732L8.3 0.257324L9.24267 1.19999L5.94267 4.49999L9.24267 7.79999L8.3 8.74266L5 5.44266L1.7 8.74266L0.757334 7.79999L4.05733 4.49999L0.757334 1.19999L1.7 0.257324L5 3.55732Z"
                          fill="#2DC3E8"
                        />
                      </svg>
                      Cancel
                    </button>
                    <button type="submit" className="blue-btn">
                      <svg
                        className="mr-r10"
                        width="12"
                        height="9"
                        viewBox="0 0 12 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.66666 6.61463L10.7947 0.485962L11.738 1.42863L4.66666 8.49996L0.423996 4.2573L1.36666 3.31463L4.66666 6.61463Z"
                          fill="white"
                        />
                      </svg>
                      Switch
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SwitchSubscriber;
