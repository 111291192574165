import React, { Suspense, useContext, useEffect, useState } from "react";
import "../../App.css";
import {
  Admin,
  BooleanField,
  Button,
  ChipField,
  Confirm,
  CustomRoutes,
  Datagrid,
  DateField,
  defaultTheme,
  DeleteButton,
  FunctionField,
  List,
  ListContextProvider,
  ListGuesser,
  NumberField,
  Resource,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  useDataProvider,
  useDelete,
  useGetList,
  useGetOne,
  useList,
  useCreate,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
  useGetIdentity,
  usePermissions,
} from "react-admin";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Button as MuiButton,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import FilterModel from "./FilterModel";
import { TableHead, TableRow, TableCell, Checkbox } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import { DatagridBody, RecordContextProvider } from "react-admin";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import FolderIcon from "@mui/icons-material/Folder";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArchiveAplicant from "./ArchiveAplicant";
import { RootState } from "../../Store/store";
import { useParams, useNavigate } from "react-router-dom";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { JSONTree } from "react-json-tree";
import SendEmailToApplicant from "./SendEmailToApplicant";
import ApplicantNameEdit from "./ApplicantNameEdit";
import ReAddApplicant from "./ReAddApplicant";
import { ATS_API_URL, ATS_DOCS_URL, typesofUsers } from "../../config";
import ResendInvite from "./ResendInvite";
import { BiSolidFilePdf } from "react-icons/bi";
import { useSelector } from "react-redux";
import moment from "moment";
import SwitchApplicant from "./SwitchApplicant";
import { identity } from "lodash";
import { checkModuleAccess, isSuperAdmin } from "../../Functions/permissions";


export const ToogleFilterInboxType = (props) => {
  const { setFilters, filterValues, displayedFilters } = useListContext();
  useEffect(() => {
    if (!filterValues.app_invitation_type) {
      setFilters({ app_invitation_type: "1" }, displayedFilters);
    }
  }, []);
  return (
    <>
      {/* <JSONTree data={filterValues}/> */}
      <ToggleButtonGroup
        sx={{ paddingBottom: 0.5, "& .Mui-selected": { fontWeight: 700 } }}
        size="small"
        value={filterValues.app_invitation_type}
        color="primary"
        exclusive
        onChange={(
          event: React.MouseEvent<HTMLElement>,
          newAlignment: string
        ) => {
          if (newAlignment) {
            setFilters({ app_invitation_type: newAlignment }, displayedFilters);
          }
        }}
        aria-label="Inbox"
      >
        <ToggleButton value={"1"} aria-label="Application">
          Application
        </ToggleButton>
        <ToggleButton value={"2"} aria-label="Docupload">
          Docupload
        </ToggleButton>
        <ToggleButton value={"3"} aria-label="Docupload">
          Guest Card
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export const FilterListInbox = (props) => {
  return (
    <>
      {/* <Button size='large' variant='outlined' startIcon={} label='' onClick={() => { }} /> */}
      <IconButton
        sx={{
          border: "1px solid",
          borderRadius: " 5px",
          padding: "6px",
          marginLeft: "-10px",
          marginBottom: "4.4px",
        }}
        size="medium"
        aria-label="delete"
      >
        <FilterListIcon />
      </IconButton>
    </>
  );
};

export const filters = [
  <SearchInput
    variant="outlined"
    sx={{ backgroundColor: "#fff" }}
    placeholder="Search"
    InputLabelProps={{
      shrink: true,
    }}
    source="q"
    alwaysOn
  />,
  <FilterModel source="filterList" alwaysOn />,
  <ToogleFilterInboxType source="app_invitation_type" alwaysOn />,

  // <TextInput alwaysOn label="Title" source="Property" variant="outlined" sx={{ backgroundColor: '#fff' }}    />,
];

export default function BasicPopover() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        color="warning"
        style={{ color: "#F9BB04" }}
        size="small"
        aria-label="info"
        aria-describedby={id}
        onClick={handleClick}
      >
        <InfoIcon style={{ fontSize: "14" }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>This is the Rent to Income Ratio.</Typography>
      </Popover>
    </div>
  );
}

const DatagridTableHeaderCell = ({ child, data }) => {
  // const isNew =
  //   (child?.props?.source === "ID" && data[0]?.NewTagID) ||
  //   (child?.props?.source === "Income" && data[0]?.NewTagIncome) ||
  //   (child?.props?.source === "Banking" && data[0]?.NewTagBanking) ||
  //   (child?.props?.source === "Other" && data[0]?.NewTagOther);

  return (
    <div className="badge-cell">
      {" "}
      {child.props.label || child.props.source}
      {/* {isNew ? <em>New</em> : <></>} */}
    </div>
  );
};

const DatagridHeader = (props) => {
  const { children, data } = props;
  return (
    <TableHead
      sx={{ backgroundColor: "#FFFFFF", border: "0.5px solid #E0E0E0" }}
    >
      <TableRow>
        {React.Children.map(children, (child) => {
          if (child)
            if (!child.props?.hidden)
              return (
                <TableCell
                  padding="none"
                  sx={{ p: 1 }}
                  key={child?.props?.source}
                >
                  <DatagridTableHeaderCell child={child} data={data} />
                </TableCell>
              );
        })}
      </TableRow>
    </TableHead>
  );
};

const MyDatagridRow = (props) => {
  const { record, id, children } = props;
  return (
    <RecordContextProvider value={record}>
      <TableRow className={props.className}>
        {React.Children.map(children, (field) => {
          if (field)
            if (!field.props?.hidden)
              return (
                <TableCell
                  padding="none"
                  data-label={field?.props?.label || field?.props?.source}
                  sx={{ p: 1 }}
                  key={`${id}-${field?.props?.source}`}
                >
                  {field}
                </TableCell>
              );
        })}
      </TableRow>
    </RecordContextProvider>
  );
};

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);
const MyDatagrid = (props) => <Datagrid {...props} body={<MyDatagridBody />} />;

export const successScreenStyle = {
  border: " 1px solid #46A96F",
  background: "#E6FDF0",
  width: 40,
  height: 40,
  borderRadius: 50,
  margin: "0px 40px",
  p: 1,
};
export const errorScreenStyle = {
  background: "#FFEEEE",
  /* Error */
  border: "1px solid #FF4848",
  width: 40,
  height: 40,
  borderRadius: 50,
  margin: "0px 40px",
  p: 1,
};
export const warnScreenStyle = {
  background: "#FFF8E2",
  border: "1px solid #FFDA6A",
  width: 40,
  height: 40,
  borderRadius: 50,
  margin: "0px 40px",
  p: 1,
};

export const appStatusStyle = {
  padding: "8px 7px",
  borderRadius: "4px",
  fontWeight: "600",
  fonSize: "12px",
  lineHeight: "16px",
};

export const screenedAppStatusStyle = {
  ...appStatusStyle,
  background: "#FFF8E2",
  color: "#F9BB04",
};

export const invitedAppStatusStyle = {
  ...appStatusStyle,
  background: "#E9E9E9",
  color: "#909090",
};

export const inProgressAppStatusStyle = {
  ...appStatusStyle,
  background: "#DDF8FF",
  color: "#1CA2C2",
};
function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        size="30px"
        {...props}
        sx={{ color: props?.value === 100 ? "green" : "orange" }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 40,
          border:
            props?.value === 100
              ? "0"
              : props?.value === 0
                ? "2px solid #E0E0E0"
                : "1px solid #E0E0E0",
        }}
      >
        {props?.value === 100 ? (
          <CheckIcon style={{ color: "green" }} />
        ) : (
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        )}
      </Box>
    </Box>
  );
}

export function CircularStatic() {
  const record = useRecordContext();
  return (
    <>
      <CircularProgressWithLabel
        value={
          record.ApplicantStatus == "Invited"
            ? 0
            : Number(record.ApplicationCompleted)
        }
      />
    </>
  );
}

export function BasicMenu({ socrStatus }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dropdown, setDropdown] = useState(
    socrStatus || "Verification Required"
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (selected) => {
    setDropdown(
      (v) => options.filter((item) => item.id == selected)[0]?.id || v
    );
    setAnchorEl(null);
  };

  const options: { id: string; name: string }[] = [
    { id: "Valid", name: "Valid" },
    { id: "Invalid", name: "Invalid" },
    { id: "Verification Required", name: "Verification Req" },
  ];

  const isSuperUser = false;

  useEffect(() => {
    if (dropdown !== socrStatus) {
      setDropdown(socrStatus || "Verification Required");
    }
  }, [socrStatus]);

  return (
    <div
      title={dropdown == "Verification Required" ? "Verif req." : dropdown}
      className={`d-flex flex-column align-items-center ${dropdown == "Invalid"
        ? "text-danger"
        : dropdown == "Valid"
          ? "text-success"
          : "text-warning"
        }`}
    >
      <p style={{ padding: "0 10px" }}>
        {dropdown == "Invalid" ? (
          <ErrorIcon color="error" />
        ) : dropdown == "Valid" ? (
          <CheckIcon color="success" />
        ) : (
          <ReportProblemIcon color="warning" />
        )}
      </p>
      {dropdown == "Verification Required"
        ? "Verif req."
        : `${dropdown.substring(0, 10)}${dropdown.length > 10 ? "..." : ""}`}
    </div>
    // <Box display={'flex'} flexDirection={'column'} justifyContent="start" alignItems={'start'} >
    //    <p style={{padding:'0px 12px'}}>{dropdown == 'Invalid' ? <ErrorIcon color='error' /> : dropdown == 'Valid' ? <CheckIcon color='success' /> : <ReportProblemIcon color='warning' />}</p>
    //     <MuiButton
    //         sx={{ padding: 0, textTransform: "capitalize" }}
    //         id="basic-button"
    //         color={dropdown == 'Invalid' ? 'error' : dropdown == 'Valid' ? 'success' : 'warning'}
    //         aria-controls={open ? 'basic-menu' : undefined}
    //         aria-haspopup="true"
    //         aria-expanded={open ? 'true' : undefined}
    //         onClick={() => isSuperUser ? handleClick : null}
    //         endIcon={isSuperUser ? <ArrowDropDownIcon /> : ""}
    //     >
    //         {options.filter((item) => item.id == dropdown)[0]?.name}
    //     </MuiButton>
    //     <Menu
    //         id="basic-menu"
    //         anchorEl={anchorEl}
    //         open={open}
    //         onClose={handleClose}
    //         MenuListProps={{
    //             'aria-labelledby': 'basic-button',
    //         }}
    //     >
    //         {options.filter((item: any) => item.id != dropdown)
    //             .map(item => <MenuItem key={item.id} onClick={() => handleClose(item.id)}>{item.name}</MenuItem>)}
    //     </Menu>
    // </Box>
  );
}

export const GetsubscriberinboxListDetails = ({
  id,
  secondaryApp,
  appType,
  subsciberIdFromRecord = null,
  appInvitationType,
  applicationType,
  archiveCancelApp,
}) => {
  const { displayedFilters, filterValues, setFilters, hideFilter } =
    useListContext();
  const AppRecord = useRecordContext();

  const { id: parmSubsciberId } = useParams();
  const subsciberId = subsciberIdFromRecord || parmSubsciberId;
  const [isLoadingViewApp, setIsLoading] = useState(false);
  const { data, total, isLoading, error } = useGetList(
    "get_subscriber_inbox_detials",
    { filter: { ApplicationNumber: id, subscriberid: subsciberId } }
  );
  console.log("data 2", data)

  const isApplicant = data?.filter((check) => check.Active == false)
  console.log("isApplicant", isApplicant && isApplicant.length)
  const isCheckedApplicant = ((isApplicant && isApplicant.length) == (data && data?.length - 1)) ? false : true
  console.log("isCheckedApplicant", isCheckedApplicant)
  const listContext = useList({ data, isLoading });
  const appSubmitted = data?.findIndex((value, index) => {
    return value.ApplicantStatus == "Submitted";
  });
  const navigate = useNavigate();
  const dataProvider = useDataProvider();
  const [create] = useCreate();
  const refresh = useRefresh();
  const { data: getUserData } = useGetIdentity();
  const { permissions } = usePermissions();
  const onClickDocsHandler = async (event, record) => {
    // get getalldocs list data
    const getalldocs = await dataProvider.getOne("getalldocs", {
      id: record?.id,
    });
    // const getApplicantDetails = await dataProvider.getOne("getApplicantDetails", { id: record?.Trnid });
    navigate("/ats/docview", {
      state: { getalldocs: getalldocs?.data?.data, record },
    });
  };

  const onClickIndividualDocsHandler = async (event, record, documentType) => {
    // get getalldocs list data
    const getalldocs = await dataProvider.getOne("getalldocs", {
      id: record?.id,
    });

    create("updatenewtagsection", {
      data: {
        ApplicationNumber: id,
        ApplicantID: record?.id,
        Section:
          documentType === "ID"
            ? 1
            : documentType === "Paystub"
              ? 2
              : documentType === "Bank Statement"
                ? 3
                : documentType === "Other"
                  ? 4
                  : "",
      },
    });

    create("updatenewtagapp", { data: { ApplicationNumber: id } });

    navigate("/ats/docview", {
      state: {
        getalldocs: getalldocs?.data?.data?.filter((item) =>
          documentType === "ID"
            ? item.DocumentType === "License" ||
            item.DocumentType === "Barcode" ||
            item.DocumentType === "Passport" ||
            item.DocumentType === "PermanentResidentID" ||
            item.DocumentType === "Visa"
            : item.DocumentType === documentType
        ),
        record,
        showHeading: true,
      },
    });
  };

  const onClickViewAppHandler = async (event, record) => {
    const payloadData = {
      applicationNumber: record?.AppID,
      applicantID: record?.id,
    };
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    // get viewappreport list data
    const viewAppReport = await dataProvider.getOne("viewappreport", {
      id: payloadData,
    });
    window.open(`${viewAppReport?.data?.data}`, "_blank");
  };

  return (
    <>
      <ListContextProvider value={listContext}>
        <fieldset disabled={getUserData?.userrole == typesofUsers.docuverusAdmin && !checkModuleAccess(permissions, "Dashboard", "Edit Application")}>
          <MyDatagrid
            bulkActionButtons={false}
            header={<DatagridHeader />}
            sx={{
              "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
              "& .RaDatagrid-expandIcon": { color: "#fff" },
              "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
              "& .RaDatagrid-headerCell": {
                fontWeight: "bold",
                fontSize: 12,
              },
              "& .RaDatagrid-table": {
                fontSize: 12,
              },
              "& .MuiToggleButton-root.Mui-selected": {
                fontWeight: 700,
              },
              "& th.MuiTableCell-root.MuiTableCell-head": {
                textAlign: "center",
              },
              "& th:nth-child(1).MuiTableCell-root.MuiTableCell-head,th:nth-child(2).MuiTableCell-root.MuiTableCell-head":
              {
                textAlign: "center",
              },
              "& .MuiTableRow-root.RaDatagrid-row": {
                verticalAlign: "top",
              },
            }}
          >
            <FunctionField
              source="Name"
              label="Name"
              render={(record) => {
                return (
                  <>
                    <Box display={"flex"} justifyContent={"start"}>
                      <Typography
                        title={`${record.FirstName} ${record.MiddleName} ${record.LastName}`}
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "inline-block",
                        }}
                      >
                        {record.FirstName} {record.MiddleName} {record.LastName}
                      </Typography>
                    </Box>
                    <Box>
                      <small className="applicant_type">
                        {record.ApplicantType}
                      </small>{" "}

                      {!(getUserData?.userrole == typesofUsers.docuverusAdmin && !checkModuleAccess(permissions, "Dashboard", "Edit Application")) && <>{archiveCancelApp && appType == "1" && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "Edit"), getUserData?.userrole)
                        ? <ApplicantNameEdit record={record} />
                        : archiveCancelApp && appType == "2" && isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", "Edit"), getUserData?.userrole)
                          ? <ApplicantNameEdit record={record} />
                          : archiveCancelApp && appType == "3" && isSuperAdmin(checkModuleAccess(permissions, "Guest Card App", "Edit"), getUserData?.userrole)
                            ? <ApplicantNameEdit record={record} />
                            : ""}</>}




                    </Box>
                  </>
                );
              }}
            />
            <FunctionField
              label={"Status"}
              source="Status"
              render={(record) => {
                return (
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    {(filterValues?.app_invitation_type == 1 ||
                      applicationType == "Application") && <CircularStatic />}
                    <ChipField
                      sx={
                        record.ApplicationStatus == "Screening"
                          ? screenedAppStatusStyle
                          : invitedAppStatusStyle
                      }
                      source="ApplicantStatus"
                    />
                  </Box>
                );
              }}
            />
            {/* {isSuperAdmin(checkModuleAccess(permissions, "Inbox", "View PII"), getUserData?.userrole) && <FunctionField
            source="ID"
            render={(record) => {
              return (
                record.ApplicantStatus != "Invited" &&
                record?.ApplicantStatus !== "Removed" && (
                  <Box>
                    {record.IDS == "No" && (
                      <div className="d-flex flex-column align-items-center">
                        <p style={{ padding: "0 10px" }}>
                          <CloseIcon />
                        </p>
                        {record.IsRequiredID && (
                          <span style={{ color: "orange" }}> Doc Req</span>
                        )}
                      </div>
                    )}
                    {record.IDS == "Yes" && (
                      <div
                        className="d-flex flex-column align-items-center"
                        onClick={(event) =>
                          onClickIndividualDocsHandler(event, record, "ID")
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <BasicMenu socrStatus={record.IDSOCRStatus} />
                      </div>
                    )}
                    <div className="badge-cell d-flex flex-column align-items-center"

                    >
                      {" "}
                      {record?.NewTagID ? <em>New</em> : <></>}
                    </div>
                  </Box>
                )
              );
            }}
          />} */}
            {(getUserData?.userrole != "Docuverus Super Admin" && getUserData?.userrole != "Docuverus Admin") ?

              appType === "1" && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "View PII"), getUserData?.userrole) ? (
                <FunctionField
                  source="ID"
                  render={(record) => {
                    return (
                      record.ApplicantStatus != "Invited" &&
                      record?.ApplicantStatus !== "Removed" && (
                        <Box>
                          {record.IDS == "No" && (
                            <div className="d-flex flex-column align-items-center">
                              <p style={{ padding: "0 10px" }}>
                                <CloseIcon />
                              </p>
                              {record.IsRequiredID && (
                                <span style={{ color: "orange" }}> Doc Req</span>
                              )}
                            </div>
                          )}
                          {record.IDS == "Yes" && (
                            <div
                              className="d-flex flex-column align-items-center"
                              onClick={(event) =>
                                onClickIndividualDocsHandler(event, record, "ID")
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <BasicMenu socrStatus={record.IDSOCRStatus} />
                            </div>
                          )}
                          <div className="badge-cell d-flex flex-column align-items-center"

                          >
                            {" "}
                            {record?.NewTagID ? <em>New</em> : <></>}
                          </div>
                        </Box>
                      )
                    );
                  }}
                />
              ) : appType === "2" && isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", "View PII"), getUserData?.userrole) ? (
                <FunctionField
                  source="ID"
                  render={(record) => {
                    return (
                      record.ApplicantStatus != "Invited" &&
                      record?.ApplicantStatus !== "Removed" && (
                        <Box>
                          {record.IDS == "No" && (
                            <div className="d-flex flex-column align-items-center">
                              <p style={{ padding: "0 10px" }}>
                                <CloseIcon />
                              </p>
                              {record.IsRequiredID && (
                                <span style={{ color: "orange" }}> Doc Req</span>
                              )}
                            </div>
                          )}
                          {record.IDS == "Yes" && (
                            <div
                              className="d-flex flex-column align-items-center"
                              onClick={(event) =>
                                onClickIndividualDocsHandler(event, record, "ID")
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <BasicMenu socrStatus={record.IDSOCRStatus} />
                            </div>
                          )}
                          <div className="badge-cell d-flex flex-column align-items-center"

                          >
                            {" "}
                            {record?.NewTagID ? <em>New</em> : <></>}
                          </div>
                        </Box>
                      )
                    );
                  }}
                />
              ) : appType === "3" && isSuperAdmin(checkModuleAccess(permissions, "Guest Card App", "View PII"), getUserData?.userrole) ? (
                <FunctionField
                  source="ID"
                  render={(record) => {
                    return (
                      record.ApplicantStatus != "Invited" &&
                      record?.ApplicantStatus !== "Removed" && (
                        <Box>
                          {record.IDS == "No" && (
                            <div className="d-flex flex-column align-items-center">
                              <p style={{ padding: "0 10px" }}>
                                <CloseIcon />
                              </p>
                              {record.IsRequiredID && (
                                <span style={{ color: "orange" }}> Doc Req</span>
                              )}
                            </div>
                          )}
                          {record.IDS == "Yes" && (
                            <div
                              className="d-flex flex-column align-items-center"
                              onClick={(event) =>
                                onClickIndividualDocsHandler(event, record, "ID")
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <BasicMenu socrStatus={record.IDSOCRStatus} />
                            </div>
                          )}
                          <div className="badge-cell d-flex flex-column align-items-center"

                          >
                            {" "}
                            {record?.NewTagID ? <em>New</em> : <></>}
                          </div>
                        </Box>
                      )
                    );
                  }}
                />
              ) : null

              : <FunctionField
                source="ID"
                render={(record) => {
                  return (
                    record.ApplicantStatus != "Invited" &&
                    record?.ApplicantStatus !== "Removed" && (
                      <Box>
                        {record.IDS == "No" && (
                          <div className="d-flex flex-column align-items-center">
                            <p style={{ padding: "0 10px" }}>
                              <CloseIcon />
                            </p>
                            {record.IsRequiredID && (
                              <span style={{ color: "orange" }}> Doc Req</span>
                            )}
                          </div>
                        )}
                        {record.IDS == "Yes" && (
                          <div
                            className="d-flex flex-column align-items-center"
                            onClick={(event) =>
                              onClickIndividualDocsHandler(event, record, "ID")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <BasicMenu socrStatus={record.IDSOCRStatus} />
                          </div>
                        )}
                        <div className="badge-cell d-flex flex-column align-items-center"

                        >
                          {" "}
                          {record?.NewTagID ? <em>New</em> : <></>}
                        </div>
                      </Box>
                    )
                  );
                }}
              />
            }

            {/* {filterValues?.inbox_type != 'DOCUPLOAD_LAST_30_DAYS' && appType != '2' && appType != '3' && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "View PII"), getUserData?.userrole) && (
            <FunctionField
              source="SSN"
              render={(record) => {
                console.log("SSN", record)

                return (
                  record.ApplicantStatus != "Invited" &&
                  record?.ApplicantStatus !== "Removed" && (
                    <Box>
                      {record.SSN == "No" && (
                        <div className="d-flex flex-column align-items-center">
                          <p style={{ padding: "0 10px" }}>
                            <CloseIcon />
                          </p>
                          {record.IsRequiredSSN && (
                            <span style={{ color: "orange" }}>Doc Req</span>
                          )}
                        </div>
                      )}
                      {record.SSN == "Yes" && (
                        <div
                          className="d-flex flex-column align-items-center"
                          onClick={(event) =>
                            onClickIndividualDocsHandler(event, record, "SSN")
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <BasicMenu socrStatus={record.SSNSOCRStatus} />
                        </div>
                      )}
                      <div
                        className="badge-cell d-flex flex-column align-items-center"

                      >
                        {" "}
                        {record?.NewTagSSN ? <em>New</em> : <></>}
                      </div>
                    </Box>
                  )
                );
              }}
            />)} */}
            {filterValues?.inbox_type != 'DOCUPLOAD_LAST_30_DAYS' && appType == "1" && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "View PII"), getUserData?.userrole) && (
              <FunctionField
                source="SSN"
                render={(record) => {
                  console.log("SSN", record)

                  return (
                    record.ApplicantStatus != "Invited" &&
                    record?.ApplicantStatus !== "Removed" && (
                      <Box>
                        {record.SSN == "No" && (
                          <div className="d-flex flex-column align-items-center">
                            <p style={{ padding: "0 10px" }}>
                              <CloseIcon />
                            </p>
                            {record.IsRequiredSSN && (
                              <span style={{ color: "orange" }}>Doc Req</span>
                            )}
                          </div>
                        )}
                        {record.SSN == "Yes" && (
                          <div
                            className="d-flex flex-column align-items-center"
                            onClick={(event) =>
                              onClickIndividualDocsHandler(event, record, "SSN")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <BasicMenu socrStatus={record.SSNSOCRStatus} />
                          </div>
                        )}
                        <div
                          className="badge-cell d-flex flex-column align-items-center"

                        >
                          {" "}
                          {record?.NewTagSSN ? <em>New</em> : <></>}
                        </div>
                      </Box>
                    )
                  );
                }}
              />
              // ) : filterValues?.inbox_type != 'DOCUPLOAD_LAST_30_DAYS' && appType == "2" && isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", "View PII"), getUserData?.userrole) ? (
              //   <FunctionField
              //     source="SSN"
              //     render={(record) => {
              //       console.log("SSN", record)

              //       return (
              //         record.ApplicantStatus != "Invited" &&
              //         record?.ApplicantStatus !== "Removed" && (
              //           <Box>
              //             {record.SSN == "No" && (
              //               <div className="d-flex flex-column align-items-center">
              //                 <p style={{ padding: "0 10px" }}>
              //                   <CloseIcon />
              //                 </p>
              //                 {record.IsRequiredSSN && (
              //                   <span style={{ color: "orange" }}>Doc Req</span>
              //                 )}
              //               </div>
              //             )}
              //             {record.SSN == "Yes" && (
              //               <div
              //                 className="d-flex flex-column align-items-center"
              //                 onClick={(event) =>
              //                   onClickIndividualDocsHandler(event, record, "SSN")
              //                 }
              //                 style={{ cursor: "pointer" }}
              //               >
              //                 <BasicMenu socrStatus={record.SSNSOCRStatus} />
              //               </div>
              //             )}
              //             <div
              //               className="badge-cell d-flex flex-column align-items-center"

              //             >
              //               {" "}
              //               {record?.NewTagSSN ? <em>New</em> : <></>}
              //             </div>
              //           </Box>
              //         )
              //       );
              //     }}
              //   />
              //  ) :  
              // && isSuperAdmin(checkModuleAccess(permissions, "Guest Card App", "View PII"), getUserData?.userrole) ? (
              //   <FunctionField
              //     source="SSN"
              //     render={(record) => {
              //       console.log("SSN", record)

              //       return (
              //         record.ApplicantStatus != "Invited" &&
              //         record?.ApplicantStatus !== "Removed" && (
              //           <Box>
              //             {record.SSN == "No" && (
              //               <div className="d-flex flex-column align-items-center">
              //                 <p style={{ padding: "0 10px" }}>
              //                   <CloseIcon />
              //                 </p>
              //                 {record.IsRequiredSSN && (
              //                   <span style={{ color: "orange" }}>Doc Req</span>
              //                 )}
              //               </div>
              //             )}
              //             {record.SSN == "Yes" && (
              //               <div
              //                 className="d-flex flex-column align-items-center"
              //                 onClick={(event) =>
              //                   onClickIndividualDocsHandler(event, record, "SSN")
              //                 }
              //                 style={{ cursor: "pointer" }}
              //               >
              //                 <BasicMenu socrStatus={record.SSNSOCRStatus} />
              //               </div>
              //             )}
              //             <div
              //               className="badge-cell d-flex flex-column align-items-center"

              //             >
              //               {" "}
              //               {record?.NewTagSSN ? <em>New</em> : <></>}
              //             </div>
              //           </Box>
              //         )
              //       );
              //     }}
              //   />
              //  null
            )}

            {filterValues?.app_invitation_type != 3 && (
              <FunctionField
                source="Income"
                render={(record) => {
                  return (
                    record.ApplicantStatus != "Invited" &&
                    record?.ApplicantStatus !== "Removed" && (
                      <Box>
                        {record.Income == "No" && (
                          <div className="d-flex flex-column align-items-center">
                            <p style={{ padding: "0 10px" }}>
                              <CloseIcon />
                            </p>
                            {record.IsRequiredIncome && (
                              <span style={{ color: "orange" }}>Doc Req</span>
                            )}
                          </div>
                        )}
                        {record.Income == "Yes" && (
                          <div
                            className="d-flex flex-column align-items-center"
                            onClick={(event) =>
                              onClickIndividualDocsHandler(
                                event,
                                record,
                                "Paystub"
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <BasicMenu socrStatus={record?.IncomeSOCRStatus} />
                          </div>
                        )}
                        {record.Income == "Yes" && (
                          <FunctionField
                            source="currency"
                            render={(record) => {
                              return (
                                <div className="d-flex flex-column align-items-center">
                                  <NumericFormat
                                    thousandSeparator=","
                                    displayType="text"
                                    thousandsGroupStyle="thousand"
                                    decimalScale={2}
                                    prefix="$"
                                    value={record.IncomeValue}
                                  />
                                </div>
                              );
                            }}
                          />
                        )}
                        <div
                          className="badge-cell d-flex flex-column align-items-center"

                        >
                          {" "}
                          {record?.NewTagIncome ? <em>New</em> : <></>}
                        </div>
                      </Box>
                    )
                  );
                }}
              />
            )}
            {filterValues?.app_invitation_type != 3 && (
              <FunctionField
                source="Banking"
                render={(record) => {
                  return (
                    record.ApplicantStatus != "Invited" &&
                    record?.ApplicantStatus !== "Removed" && (
                      <Box>
                        {record.Banking == "No" && (
                          <div className="d-flex flex-column align-items-center">
                            <p style={{ padding: "0 10px" }}>
                              <CloseIcon />
                            </p>
                            {record.IsRequiredBanking && (
                              <span style={{ color: "orange" }}>Doc Req</span>
                            )}
                          </div>
                        )}
                        {record.Banking == "Yes" && (
                          <div
                            className="d-flex flex-column align-items-center"
                            onClick={(event) =>
                              onClickIndividualDocsHandler(
                                event,
                                record,
                                "Bank Statement"
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <BasicMenu socrStatus={record.BankingSOCRStatus} />
                          </div>
                        )}
                        <div
                          className="badge-cell d-flex flex-column align-items-center"

                        >
                          {" "}
                          {record?.NewTagBanking ? <em>New</em> : <></>}
                        </div>
                      </Box>
                    )
                  );
                }}
              />
            )}
            {filterValues?.app_invitation_type != 3 && (
              <FunctionField
                source="Other"
                render={(record) => {
                  return (
                    record.ApplicantStatus != "Invited" &&
                    record?.ApplicantStatus !== "Removed" && (
                      <Box>
                        {record.Misc == "No" && (
                          <div className="d-flex flex-column align-items-center">
                            <p style={{ padding: "0 10px" }}>
                              <CloseIcon />
                            </p>
                            {record.IsRequiredID && "No Doc"}
                          </div>
                        )}
                        {record.Misc == "Yes" && (
                          <div className="d-flex flex-column align-items-center">
                            <MuiButton
                              sx={{
                                justifyContent: "center",
                                padding: "0 10px",
                                display: "flex",
                                flexDirection: "column",
                                "& .MuiButton-startIcon.MuiButton-iconSizeMedium":
                                  { margin: 0 },
                              }}
                              startIcon={<CheckIcon color="primary" />}
                              onClick={(event) =>
                                onClickIndividualDocsHandler(
                                  event,
                                  record,
                                  "Other"
                                )
                              }
                            >
                              View
                            </MuiButton>
                          </div>
                        )}

                        <div
                          className="badge-cell d-flex flex-column align-items-center"

                        >
                          {" "}
                          {record?.NewTagOther ? <em>New</em> : <></>}
                        </div>
                      </Box>
                    )
                  );
                }}
              />
            )}
            {appType == "1" && (
              <FunctionField
                source="Docs"
                render={(record) => {
                  return (
                    archiveCancelApp &&
                    record?.ApplicantDocs &&
                    record.ApplicantStatus != "Invited" &&
                    record?.ApplicantStatus !== "Removed" && isSuperAdmin(checkModuleAccess(permissions, "View Docs", ""), getUserData?.userrole) && (
                      <Box display={"flex"} justifyContent={"center"}>
                        <MuiButton
                          onClick={(event) => onClickDocsHandler(event, record)}
                          sx={{
                            ".MuiButton-startIcon": { paddingLeft: "10px" },
                            minWidth: "80px",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                            textTransform: "capitalize",
                          }}
                          startIcon={<FolderIcon />}
                        >
                          View
                        </MuiButton>
                      </Box>
                    )
                  );
                }}
              />
            )}
            {appType == "1" && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "View Application"), getUserData?.userrole) && (
              <FunctionField
                source="App"
                render={(record) => {
                  return (
                    record.ApplicantStatus != "Invited" &&
                    record?.ApplicantStatus !== "Removed" &&
                    record.ApplicantStatus === "Submitted" && (
                      <Box display={"flex"} justifyContent={"center"}>
                        <MuiButton
                          onClick={(event) =>
                            onClickViewAppHandler(event, record)
                          }
                          sx={{
                            ".MuiButton-startIcon": { paddingLeft: "10px" },
                            minWidth: "80px",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                            textTransform: "capitalize",
                          }}
                        // startIcon={<PictureAsPdfIcon />}
                        >
                          {/* View */}
                          {isLoadingViewApp ? (
                            <Box sx={{ display: "flex" }}>
                              <CircularProgress size="25px" />
                            </Box>
                          ) : (
                            <>
                              <PictureAsPdfIcon />
                              View
                            </>
                          )}
                        </MuiButton>
                      </Box>
                    )
                  );
                }}
              />
            )}
            {/* <fieldset disabled={getUserData?.userrole == typesofUsers.docuverusAdmin && !checkModuleAccess(permissions, "Dashboard", "Edit Application")}> */}
            {
              (getUserData?.userrole != "Docuverus Super Admin" && getUserData?.userrole != "Docuverus Admin") ?
                appInvitationType == 1 && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "Remove"), getUserData?.userrole) ? (
                  <FunctionField
                    source="Action"
                    render={(record) => {
                      console.log("record", record)
                      return (
                        archiveCancelApp && (
                          <Box display={"flex"} justifyContent={"center"}>
                            {record.ApplicantType == "Applicant" ? (
                              (data && data?.length > 1 && isCheckedApplicant) ? (
                                <div className="modal-sm">
                                  {/* <SwitchApplicant record={data} subscriberId={subsciberId}  />  */}
                                </div>)
                                :
                                <small>&nbsp;</small>
                            ) : (record?.Active &&
                              record?.ApplicantStatus == "Invited") ||
                              (record?.Active &&
                                record?.ApplicantStatus == "In Progress") ? (
                              <ArchiveAplicant
                                record={record}
                                subsciberId={subsciberId}
                              />
                            ) : record?.ApplicantStatus == "Removed" ? (
                              <ReAddApplicant
                                record={record}
                                subsciberId={subsciberId}
                              />
                            ) : (
                              ""
                            )}
                          </Box>
                        )
                      );
                    }}
                  />
                ) : appInvitationType == 2 && isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", "Remove"), getUserData?.userrole) ? (
                  <FunctionField
                    source="Action"
                    render={(record) => {
                      console.log("record", record)
                      return (
                        archiveCancelApp && (
                          <Box display={"flex"} justifyContent={"center"}>
                            {record.ApplicantType == "Applicant" ? (
                              (data && data?.length > 1 && isCheckedApplicant) ? (
                                <div className="modal-sm">
                                  {/* <SwitchApplicant record={data} subscriberId={subsciberId}  />  */}
                                </div>)
                                :
                                <small>&nbsp;</small>
                            ) : (record?.Active &&
                              record?.ApplicantStatus == "Invited") ||
                              (record?.Active &&
                                record?.ApplicantStatus == "In Progress") ? (
                              <ArchiveAplicant
                                record={record}
                                subsciberId={subsciberId}
                              />
                            ) : record?.ApplicantStatus == "Removed" ? (
                              <ReAddApplicant
                                record={record}
                                subsciberId={subsciberId}
                              />
                            ) : (
                              ""
                            )}
                          </Box>
                        )
                      );
                    }}
                  />
                ) : appInvitationType == 3 && isSuperAdmin(checkModuleAccess(permissions, "Guest Card App", "Remove"), getUserData?.userrole) ? (
                  <FunctionField
                    source="Action"
                    render={(record) => {
                      console.log("record", record)
                      return (
                        archiveCancelApp && (
                          <Box display={"flex"} justifyContent={"center"}>
                            {record.ApplicantType == "Applicant" ? (
                              (data && data?.length > 1 && isCheckedApplicant) ? (
                                <div className="modal-sm">
                                  {/* <SwitchApplicant record={data} subscriberId={subsciberId}  />  */}
                                </div>)
                                :
                                <small>&nbsp;</small>
                            ) : (record?.Active &&
                              record?.ApplicantStatus == "Invited") ||
                              (record?.Active &&
                                record?.ApplicantStatus == "In Progress") ? (
                              <ArchiveAplicant
                                record={record}
                                subsciberId={subsciberId}
                              />
                            ) : record?.ApplicantStatus == "Removed" ? (
                              <ReAddApplicant
                                record={record}
                                subsciberId={subsciberId}
                              />
                            ) : (
                              ""
                            )}
                          </Box>
                        )
                      );
                    }}
                  />
                ) : null
                :
                <FunctionField
                  source="Action"
                  render={(record) => {
                    console.log("record", record)
                    return (
                      archiveCancelApp && (
                        <Box display={"flex"} justifyContent={"center"}>
                          {record.ApplicantType == "Applicant" ? (
                            (data && data?.length > 1 && isCheckedApplicant) ? (
                              <div className="modal-sm">
                                {/* <SwitchApplicant record={data} subscriberId={subsciberId}  />  */}
                              </div>)
                              :
                              <small>&nbsp;</small>
                          ) : (record?.Active &&
                            record?.ApplicantStatus == "Invited") ||
                            (record?.Active &&
                              record?.ApplicantStatus == "In Progress") ? (
                            <ArchiveAplicant
                              record={record}
                              subsciberId={subsciberId}
                            />
                          ) : record?.ApplicantStatus == "Removed" ? (
                            <ReAddApplicant
                              record={record}
                              subsciberId={subsciberId}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      )
                    );
                  }}
                />
            }
            {/* </fieldset> */}
            {/* <fieldset disabled={getUserData?.userrole == typesofUsers.docuverusAdmin && !checkModuleAccess(permissions, "Dashboard", "Edit Application")}> */}
            <FunctionField
              label="Result"
              render={(record) =>
                (AppRecord?.ApplicationStatus ===
                  "CompletedConditionallyApproved" ||
                  AppRecord?.ApplicationStatus === "Verified" ||
                  AppRecord?.ApplicationStatus === "Unverifiable" ||
                  AppRecord?.ApplicationStatus ===
                  "Further Documentation Required" ||
                  AppRecord?.ApplicationStatus === "Screening" ||
                  AppRecord?.ApplicationStatus === "Submitted" ||
                  AppRecord?.ApplicationStatus === "Review Pending" ||
                  AppRecord?.ApplicationStatus === "Completed-Approved" ||
                  AppRecord?.ApplicationStatus === "Completed-Unacceptable") &&
                AppRecord.ApplicationStatus !== "Invited" &&
                AppRecord.ApplicationStatus !== "Archieved" &&
                AppRecord.ApplicationStatus !== "Cancelled" &&
                AppRecord?.ApplicantStatus !== "In Progress" &&
                archiveCancelApp && (
                  <>
                    {appInvitationType == 1 && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "View Report"), getUserData?.userrole)
                      ? <MuiButton
                        sx={{
                          ".MuiButton-startIcon": { paddingLeft: "10px" },
                          minWidth: "80px",
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                          textTransform: "capitalize",
                          width: "100%",
                        }}
                        onClick={() => {
                          const authJson = localStorage.getItem("auth") || "";
                          let authObj = authJson ? JSON.parse(authJson) : {};
                          if (authObj.data && authObj.data.hasOwnProperty('permissions')) {
                            delete authObj.data.permissions;
                          }
                          if (authObj.data && authObj.data.hasOwnProperty('systemNotification')) {
                            delete authObj.data.systemNotification;
                          }


                          const updatedAuthJson = JSON.stringify(authObj);

                          window.open(
                            `${ATS_API_URL}/applicant/document/printpdf?url=${window.location.protocol
                            }//${window.location.host
                            }/admin/docupload_admin/Office/11/TestRealEstateCompany-Philadelphia/NuEF07JC8UuAluc0k-r7Yg/${record.Trnid +
                            `?auth=` +
                            encodeURIComponent(updatedAuthJson) +
                            `&createdDateAsPerClientRequest=` +
                            moment(AppRecord?.AppDate).format(
                              "MM/DD/YYYY h:mm A z"
                            )
                            }`,
                            "_blank"
                          );
                        }}
                        startIcon={<BiSolidFilePdf />}
                        variant="text"
                        color="primary"
                      >
                        View
                      </MuiButton>
                      : appInvitationType == 2 && isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", "View Report"), getUserData?.userrole)
                        ? <MuiButton
                          sx={{
                            ".MuiButton-startIcon": { paddingLeft: "10px" },
                            minWidth: "80px",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                            textTransform: "capitalize",
                            width: "100%",
                          }}
                          onClick={() => {
                            const authJson = localStorage.getItem("auth") || "";
                            let authObj = authJson ? JSON.parse(authJson) : {};
                            if (authObj.data && authObj.data.hasOwnProperty('permissions')) {
                              delete authObj.data.permissions;
                            }
                            if (authObj.data && authObj.data.hasOwnProperty('systemNotification')) {
                              delete authObj.data.systemNotification;
                            }


                            const updatedAuthJson = JSON.stringify(authObj);

                            window.open(
                              `${ATS_API_URL}/applicant/document/printpdf?url=${window.location.protocol
                              }//${window.location.host
                              }/admin/docupload_admin/Office/11/TestRealEstateCompany-Philadelphia/NuEF07JC8UuAluc0k-r7Yg/${record.Trnid +
                              `?auth=` +
                              encodeURIComponent(updatedAuthJson) +
                              `&createdDateAsPerClientRequest=` +
                              moment(AppRecord?.AppDate).format(
                                "MM/DD/YYYY h:mm A z"
                              )
                              }`,
                              "_blank"
                            );
                          }}
                          startIcon={<BiSolidFilePdf />}
                          variant="text"
                          color="primary"
                        >
                          View
                        </MuiButton>
                        : appInvitationType == 3 && isSuperAdmin(checkModuleAccess(permissions, "Guest Card App", "View Report"), getUserData?.userrole)
                          ? <MuiButton
                            sx={{
                              ".MuiButton-startIcon": { paddingLeft: "10px" },
                              minWidth: "80px",
                              justifyContent: "center",
                              display: "flex",
                              flexDirection: "column",
                              textTransform: "capitalize",
                              width: "100%",
                            }}
                            onClick={() => {
                              const authJson = localStorage.getItem("auth") || "";
                              let authObj = authJson ? JSON.parse(authJson) : {};
                              if (authObj.data && authObj.data.hasOwnProperty('permissions')) {
                                delete authObj.data.permissions;
                              }
                              if (authObj.data && authObj.data.hasOwnProperty('systemNotification')) {
                                delete authObj.data.systemNotification;
                              }


                              const updatedAuthJson = JSON.stringify(authObj);

                              window.open(
                                `${ATS_API_URL}/applicant/document/printpdf?url=${window.location.protocol
                                }//${window.location.host
                                }/admin/docupload_admin/Office/11/TestRealEstateCompany-Philadelphia/NuEF07JC8UuAluc0k-r7Yg/${record.Trnid +
                                `?auth=` +
                                encodeURIComponent(updatedAuthJson) +
                                `&createdDateAsPerClientRequest=` +
                                moment(AppRecord?.AppDate).format(
                                  "MM/DD/YYYY h:mm A z"
                                )
                                }`,
                                "_blank"
                              );
                            }}
                            startIcon={<BiSolidFilePdf />}
                            variant="text"
                            color="primary"
                          >
                            View
                          </MuiButton>
                          : ""
                    }
                  </>
                )
              }
            />

            {/* </fieldset> */}
            <FunctionField
              source="Message"
              render={(record) => {
                return (
                  <>
                    {/* <JSONTree data={filterValues}/>   */}
                    {archiveCancelApp && (
                      <Box display={"flex"} justifyContent={"center"}>
                        {((appInvitationType === 1 ||
                          AppRecord?.ApplicationType === "Application" ||
                          filterValues.app_invitation_type == "1") &&
                          record.ApplicantStatus === "Invited") ||
                          appInvitationType === 2 ||
                          AppRecord?.ApplicationType === "Doc Upload" ||
                          filterValues.app_invitation_type == "2" ||
                          appInvitationType === 3 ||
                          AppRecord?.ApplicationType === "Guest Card" ||
                          filterValues.app_invitation_type == "3" ? (
                          <ResendInvite
                            record={record}
                            ApplicationNumber={id}
                            subscriberId={subsciberId}
                          />
                        ) : null}
                        {record?.ApplicantStatus !== "Removed" && (
                          <SendEmailToApplicant record={[record]} />
                        )}
                      </Box>
                    )}
                  </>
                );
              }}
            />
          </MyDatagrid>
        </fieldset>
      </ListContextProvider>
    </>
  );
};
